import React from 'react';
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import gsap, {CSSPlugin} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import HoverBox from "../../../components/HoverBox";
import ReactHtmlParser from "react-html-parser";
gsap.registerPlugin(ScrollTrigger, CSSPlugin);

const Blogs = ({title,data}) => {



    return (
        <StyledBlogs className='skill pt-150 pb-150'>
            {/*//container*/}
            <Container>
                {
                    title &&
                    <Row>
                        <Col md={12} className={'header-section '}>
                           <div className="text-right-anim left-to-right">
                               <h2 className={'fade-up'}>{title}</h2>
                           </div>
                        </Col>
                    </Row>
                }

                <Row>

                   <Col md={8}>
                       <Row>
                           {
                               data && data?.length > 0 &&
                               data?.map((e,index) => {
                                   return(
                                       <Col className={'fade-up'} md={6}>
                                           <HoverBox title={e?.title} des={ReactHtmlParser(e?.content)} />
                                       </Col>
                                   )
                               })
                           }


                       </Row>
                   </Col>



                </Row>



                {/*fixed position name*/}
                {
                    window.innerWidth > 1000 &&
                    <div className="fixed-name right-align-text profile-name">
                        <h3>CHOOSE YOUR NEED</h3>
                    </div>
                }
            </Container>

        </StyledBlogs>
    );
};

const StyledBlogs = styled.section`
  overflow: hidden;
    h2{
      margin-bottom: 80px;
    }
  
  .container{
    position: relative;
  }
  
  
  //fixed position name
  .fixed-name {
    position: absolute;
    bottom: -150px;
    z-index: 1;
    left: 10%;
    right: 0;

    h3 {
      font-size: 120px;
      font-weight: 500;
      line-height: 120px;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      @media (min-width: 1800px) {
        font-size: 120px;
        line-height: 120px;
      }
      @media (max-width: 767px) {
        font-size: 42px;
        line-height: 55px;
      }
    }
  }

  
  
  @media (min-width: 1000px) {
    .header-section{
      position: relative;
      min-height: 180px;
      .text-right-anim{
        position: absolute;
        right: 60px;
        left: 0;
        transform: translateX(80%);
      }
    }
  }
  
  @media(max-width: 1000px){
    .col-md-9, .col-md-6{
      flex: 0 0 100%;
      max-width: 100%;
    }
  }

`;

export default Blogs;
