import React from 'react';
import styled from 'styled-components';
import {hover, primary, secondary} from "../styles/globalStyleVars";
import {Link} from "react-router-dom";
import ReactHtmlParser from "react-html-parser";


const ExperienceItem = ({name, company, date, des}) => {


    return (<StyledExperienceItem className={`dc-ExperienceItem fade-up`}>
            <div className="item-resume ">
                <div className="item-resume-summary">
                    <h4 className="item-resume-year">{date}</h4>
                </div>
                <div className="item-resume-details">
                    <h4>{name}</h4>
                    <h5 className="item-resume-company">{company}</h5>
                    {ReactHtmlParser(des)}
                </div>
            </div>
        </StyledExperienceItem>)
};

const StyledExperienceItem = styled.div`

  .item-resume {
    display: flex;
    margin-bottom: 30px;
    .item-resume-summary {
      align-self: flex-start;
      flex: 0 0 220px;
      max-width: 220px;
      padding-left: 20px;
      position: relative;
      &:before {
        background: ${primary};
        border: 3px solid ${secondary};
        border-radius: 100px;
        content: "";
        height: 15px;
        left: -9px;
        position: absolute;
        top: 6px;
        width: 15px;
      }
      .item-resume-year {
        margin-bottom: 0;
      }
    }
    .item-resume-details {
      padding-left: 50px;
      position: relative;
      &:before {
        background: ${primary};
        content: "";
        height: 1px;
        left: 0;
        position: absolute;
        top: 10px;
        width: 30px;
      }

      h5{
        margin-bottom: 20px;
      }
    }
  }

  @media(max-width: 1000px){
    .item-resume{
      flex-direction: column;
      .item-resume-summary{
        flex: 0 0 100%;
        max-width: 100%;
      }
      .item-resume-details{
        margin-top: 20px;
        padding-left: 20px;
        &:before {
          background: ${primary};
          content: "";
          height: 1px;
          left: -9px;
          position: absolute;
          top: 10px;
          width: 15px;
        }
      }
    }
  }
`;


export default ExperienceItem;
