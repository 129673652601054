import React from 'react';
import styled from 'styled-components';
import {primary, white} from "../styles/globalStyleVars";
import DownIcon from "../components/svg/DownIcon";
import {Link} from "react-router-dom";
import ReactHtmlParser from "react-html-parser";

const HoverBox = ({title, des, reverse}) => {


    return (

        <StyledHoverBox className={`hover-box ${reverse ? 'reverse' : ''}`}>
            <Link to={'#'} className={'hover-box-link'}>
                <div className="box-wrapper">
                    <h3>{title ? ReactHtmlParser(title) : 'Web Design'}</h3>
                    <p>{des ? des : 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem tenetur ratione quod.'}</p>

                    <div className="icon">
                        <DownIcon/>
                    </div>
                </div>
            </Link>
        </StyledHoverBox>

    )
};


const StyledHoverBox = styled.div`
  margin-bottom: 40px;

  .box-wrapper {
    border-top: 1px solid ${white};
    border-bottom: 1px solid ${white};
    padding: 40px 0 20px;
    position: relative;
    transition: all cubic-bezier(.74, 0, .24, .99) 0.5s;

    h3 {
      transition: all cubic-bezier(.74, 0, .24, .99) 0.2s;
      position: relative;
      z-index: 1;
      margin-bottom: 20px !important;
    }

    p {
      margin-bottom: 90px;
      position: relative;
      z-index: 1;
      transition: all cubic-bezier(.74, 0, .24, .99) 0.2s;

    }

    .icon {
      text-align: right;
      padding-right: 20px;
      position: relative;
      z-index: 1;
    }

    &:after {
      background: ${primary};
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      content: '';
      height: 100%;
      width: 0;
      transition: all cubic-bezier(.74, 0, .24, .99) 0.5s;

    }


    &:hover {
      padding-left: 30px;

      &:after {
        width: 100%;
      }

      h2, p, a {
        color: ${white};
      }

      #Rectangle_5479 {
        rx: 25px;
      }

   
    }
  }


  &.reverse {
    .box-wrapper {
      padding-left: 30px;

      &:after {
        width: 100%;
      }

      h2, p, a {
        color: ${white};
      }

      #Rectangle_5479 {
        rx: 25px;
      }

  
    }
  }
`;

export default React.memo(HoverBox);














