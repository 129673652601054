import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import gsap, {CSSPlugin} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import ReactPaginate from 'react-paginate';
import BlogItem from "../../../components/BlogItem";
gsap.registerPlugin(ScrollTrigger, CSSPlugin);

const Blogs = ({title, data}) => {



    // pagination
    const items = [{
        blog_title: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eveniet, pariatur.',
        blog_date: '10 July 2023', // You can replace this with an actual date
        category: ['wordpress', 'react'], // Example categories, customize as needed
        images: '/images/dynamic/blog-1.jpg', // Example image names, customize as needed
        link: '/blog/blog-details', // Example link names, customize as needed
    }, {
        blog_title: 'Lorem  dolor  amet, consectetur  elit. , pariatur.',
        blog_date: '1 July 2023', // You can replace this with an actual date
        category: ['wordpress'], // Example categories, customize as needed
        images: '/images/dynamic/blog-2.jpg', // Example image names, customize as needed
        link: '/blog/blog-details', // Example link names, customize as needed
    }, {
        blog_title: 'Lorem ipsum dolor  adipisicing elit. pariatur.',
        blog_date: '13 June 2023', // You can replace this with an actual date
        category: ['wordpress', 'react'], // Example categories, customize as needed
        images: '/images/dynamic/blog-3.avif', // Example image names, customize as needed
        link: '/blog/blog-details', // Example link names, customize as needed
    }, {
        blog_title: 'Lorem adipisicing elit. Eveniet, pariatur.',
        blog_date: '18 June 2023', // You can replace this with an actual date
        category: ['wordpress', 'react'], // Example categories, customize as needed
        images: '/images/dynamic/blog-4.avif', // Example image names, customize as needed
        link: '/blog/blog-details', // Example link names, customize as needed
    }, {
        blog_title: 'Lorem ipsum dolor sit  pariatur.',
        blog_date: '13 September 2023', // You can replace this with an actual date
        category: ['wordpress'], // Example categories, customize as needed
        images: '/images/dynamic/blog-5.avif', // Example image names, customize as needed
        link: '/blog/blog-details', // Example link names, customize as needed
    }, {
        blog_title: 'Lorem ipsum adipisicing elit. Eveniet, pariatur.',
        blog_date: '22 September 2023', // You can replace this with an actual date
        category: ['wordpress', 'HTML'], // Example categories, customize as needed
        images: '/images/dynamic/blog-6.avif', // Example image names, customize as needed
        link: '/blog/blog-details', // Example link names, customize as needed
    }, {
        blog_title: 'Lorem ipsum dolor sit amet, consectetur pariatur.',
        blog_date: '25 September 2023', // You can replace this with an actual date
        category: ['wordpress', 'jQuery'], // Example categories, customize as needed
        images: '/images/dynamic/blog-7.avif', // Example image names, customize as needed
        link: '/blog/blog-details', // Example link names, customize as needed
    }, {
        blog_title: 'Lorem ipsum dolor sit amet pariatur.',
        blog_date: '7 September 2023', // You can replace this with an actual date
        category: ['wordpress'], // Example categories, customize as needed
        images: '/images/dynamic/blog-8.avif', // Example image names, customize as needed
        link: '/blog/blog-details', // Example link names, customize as needed
    }];
    const total = items.length


    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 4; // Set the number of items per page

    const pageCount = Math.ceil(items.length / itemsPerPage);
    const offset = currentPage * itemsPerPage;

    const displayedItems = items.slice(offset, offset + itemsPerPage);

    const handlePageChange = (selectedPage) => {
        setCurrentPage(selectedPage.selected);
    };


    return (
        <StyledBlogs className='skill pt-150 pb-150'>
            {/*//container*/}
            <Container>
                {
                    title
                        ?
                        <Row>
                            <Col md={12} className={'header-section '}>
                                <div className="text-right-anim left-to-right">
                                    <h2 className={'fade-up'}>{title}</h2>
                                </div>
                            </Col>
                        </Row>
                        :

                        ''
                }
                <Row>

                   <Col md={8}>
                       <Row>
                           {data &&
                               data.map((item) => (
                                   <Col md={6}>
                                       <BlogItem date={item.date} link={item?.link}  category={item.category} image={item.featured_image ? item.featured_image : 'https://placehold.co/400x250'} title={item.title}/>
                                   </Col>
                               ))}

                       </Row>
                   </Col>


                    {/*<Col md={12}>*/}
                    {/*    <ReactPaginate*/}
                    {/*        previousLabel={'<'}*/}
                    {/*        nextLabel={'>'}*/}
                    {/*        breakLabel={'...'}*/}
                    {/*        breakClassName={'break-me'}*/}
                    {/*        pageCount={pageCount}*/}
                    {/*        marginPagesDisplayed={2}*/}
                    {/*        pageRangeDisplayed={5}*/}
                    {/*        onPageChange={handlePageChange}*/}
                    {/*        containerClassName={'pagination'}*/}
                    {/*        activeClassName={'active'}*/}
                    {/*    />*/}
                    {/*</Col>*/}
                </Row>



                {/*fixed position name*/}
                {
                    window.innerWidth > 1000 &&
                    <div className="fixed-name profile-name">
                        <h3>DISCOVER THE ARTICLES</h3>
                    </div>
                }
            </Container>

        </StyledBlogs>
    );
};

const StyledBlogs = styled.section`
  overflow: hidden;
    h2{
      margin-bottom: 80px;
    }
  
  .container{
    position: relative;
  }

  //fixed position name
  //fixed position name
  .fixed-name {
    position: absolute;
    bottom: -150px;
    z-index: 1;
    left: 10%;
    right: 0;

    h3 {
      font-size: 120px;
      font-weight: 500;
      line-height: 120px;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      @media (min-width: 1800px) {
        font-size: 120px;
        line-height: 120px;
      }
      @media (max-width: 767px) {
        font-size: 42px;
        line-height: 55px;
      }
    }

  }

  @media (min-width: 1000px) {
    .header-section{
      position: relative;
      min-height: 180px;
      .text-right-anim{
        position: absolute;
        right: 60px;
        left: 0;
        transform: translateX(80%);
      }
    }
  }
  
  @media(max-width: 1000px){
    .col-md-9{
      flex: 0 0 100%;
      max-width: 100%;
    }
  }

`;

export default Blogs;
