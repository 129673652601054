import React from 'react';
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import {useLocation} from "react-router-dom";
import gsap, {CSSPlugin} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import {Tab, TabList, TabPanel, Tabs} from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import PortfolioItem from "../../../components/PortfolioItem";
import ReactHtmlParser from "react-html-parser";

gsap.registerPlugin(ScrollTrigger, CSSPlugin);

const Portfolio = ({title, data}) => {


    const location = useLocation();

    return (
        <StyledPortfolio className='skill pt-150 pb-150'>
            {/*//container*/}
            <Container>
                {
                    title
                        ?
                        <Row>
                            <Col md={12} className={'header-section '}>
                                <div className="text-right-anim left-to-right">
                                    <h2 className={'fade-up'}>{title}</h2>
                                </div>
                            </Col>
                        </Row>
                        :

                        ''
                }

                <Row>
                    <Col md={8}>
                        <Tabs>

                            <TabList>
                                {
                                    data?.filter && data?.filter?.length > 0 &&
                                    data?.filter?.map((e, index) => {
                                        return (
                                            <Tab id={e?.slug} key={index + e?.slug}>{e?.name}</Tab>

                                        )

                                    })
                                }
                            </TabList>
                            {
                                data?.filter && data?.filter?.length > 0 &&
                                data?.filter?.map((e, index) => {
                                    // data?.portfolio_list && data?.portfolio_list?.length > 0 &&
                                    return (
                                        <TabPanel id={e?.slug} key={index + e?.slug}>
                                            <Row>

                                                {
                                                    data?.portfolio_list?.[e?.slug]?.items && data?.portfolio_list?.[e?.slug]?.items?.length > 0 &&
                                                    data?.portfolio_list?.[e?.slug]?.items?.map((event, item) => {
                                                        return (
                                                            <Col md={6} key={item}>
                                                                <PortfolioItem link={event?.acf_fields?.live_url ? event?.acf_fields?.live_url : event?.acf_fields?.github_link} title={ReactHtmlParser(event?.title)} description={event?.content} image={event?.featured_image}/>
                                                            </Col>
                                                        )

                                                    })
                                                }


                                            </Row>
                                        </TabPanel>
                                    )


                                })
                            }

                            {/*<TabPanel>*/}
                            {/*    <Row>*/}
                            {/*        <Col md={6}>*/}
                            {/*            <PortfolioItem title={'ARSHA'} description={'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Error.'} image={'/images/dynamic/sb-ui-kit-pro-vue.jpeg'}/>*/}
                            {/*        </Col>*/}
                            {/*        <Col md={6}>*/}
                            {/*            <PortfolioItem title={'BOOTCAMP'} description={'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Error.'} image={'/images/dynamic/sb-ui-kit-pro.jpeg'}/>*/}
                            {/*        </Col>*/}


                            {/*    </Row>*/}
                            {/*</TabPanel>*/}
                        </Tabs>
                    </Col>

                </Row>

                {/*fixed position name*/}
                {
                    window.innerWidth > 1000 &&
                    <div className="fixed-name profile-name">
                        <h3>RECENT WORK</h3>
                    </div>
                }
            </Container>

        </StyledPortfolio>
    );
};

const StyledPortfolio = styled.section`
    overflow: hidden;

    h2 {
        margin-bottom: 80px;
    }

    .container {
        position: relative;
    }

    //fixed position name

    .fixed-name {
        position: absolute;
        bottom: -150px;
        z-index: 1;
        left: 10%;
        right: 0;

        h3 {
            font-size: 120px;
            font-weight: 500;
            line-height: 120px;
            letter-spacing: 0.5px;
            text-transform: uppercase;
            @media (min-width: 1800px) {
                font-size: 120px;
                line-height: 120px;
            }
            @media (max-width: 767px) {
                font-size: 42px;
                line-height: 55px;
            }
        }

    }


    @media (min-width: 1000px) {
        .header-section {
            position: relative;
            min-height: 180px;

            .text-right-anim {
                position: absolute;
                right: 60px;
                left: 0;
                transform: translateX(80%);
            }
        }
    }


    @media (max-width: 1000px) {
        .col-md-9 {
            flex: 0 0 100%;
            max-width: 100%;
        }
    }

`;

export default Portfolio;
