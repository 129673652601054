import React from 'react';
import styled from "styled-components";

const HamburgerMobile = () => {
    return (
        <StyledHamburgerMobile>
            <div className={'hamburger-icon'}>
                <span className={'first-span'}></span>
                <span className={'second-span'}></span>
                <span className={'third-span'}></span>
            </div>
        </StyledHamburgerMobile>
    );
};

const StyledHamburgerMobile = styled.div`
  .hamburger-icon {
    display: inline-block;
    cursor: pointer;
    width: 30px;
    height: 16px;
    position: relative;
    margin: 3px 0px 0px 23px;
  }

  .hamburger-icon span {
    display: block;
    position: absolute;
    height: 2px;
    width: 110%;
    background: #fff;
    transition: transform 0.3s ease;


    &:nth-child(1) {
      top: 0;
      width: 75%;
      right: 0;
      transition: width .3s ease;
    }

    &:nth-child(2) {
      display: block !important;
      right: 0;
      top: 50%;
      margin-top: -1px;
      transition: width .3s ease;
    }

    &:nth-child(3) {
      bottom: 0;
      width: 75%;
      right: 0;
      transition: width .3s ease;
    }

  }

  /* Animation for the active/hamburger open state */

  .hamburger-icon:hover span:nth-child(1) {
    width: 110%;
    transition: width .3s ease;
  }

  .hamburger-icon:hover span:nth-child(2) {
    display: block;
    width: 75%;
    transition: width .3s ease;
  }

  .hamburger-icon:hover span:nth-child(3) {
    width: 110%;
    transition: width .3s ease;
  }
`;

export default React.memo(HamburgerMobile);
