import React, {useEffect, useRef, useState} from "react";
import styled from "styled-components";
import {Container, Row, Col, Accordion} from "react-bootstrap";
import {Link} from "react-router-dom";
import {gsap} from "gsap";
import HamburgerMobile from "./../../components/svg/HamburgerMobile";
import CloseIconMobile from "./../../components/svg/CloseIconMobile";
import {black, primary, white,  hover} from "../../styles/globalStyleVars";


const MyComponent = ({data}) => {


    // variables
    const mobileMenuRaf = useRef()
    const menuRef = useRef()
    const hamburgerRef = useRef();
    const hamburgerCloseRef = useRef();
    const [scrollDirection, setScrollDirection] = useState('down');
    const [showSubmenu, setShowSubmenu] = useState(null);
    const [showSubmenuSub, setShowSubmenuSub] = useState(null);
    const [showSubmenuSubSub, setShowSubmenuSubSub] = useState(null);
    const targetElementRef = useRef(null);
    const [scrollUp, setScrollUp] = useState(false);
    const [scrollDown, setScrollDown] = useState(false);


    // get all siblings
    const getSiblings = (e) => {
        // for collecting siblings
        let siblings = [];
        // if no parent, return no sibling
        if (!e.parentNode) {
            return siblings;
        }
        // first child of the parent node
        let sibling = e.parentNode.firstChild;
        // collecting siblings
        while (sibling) {
            if (sibling.nodeType === 1 && sibling !== e) {
                siblings.push(sibling);
            }
            sibling = sibling.nextSibling;
        }
        return siblings;
    };


    //  menu handle
    const remove_all_mobile = (e) => {
        var menuOpen = document.querySelectorAll(".Mobile-menu-wrap");
        var hamburger = document.querySelectorAll(".hamburger");
        var menu = document.querySelectorAll(".main_child");
        var menuSub = document.querySelectorAll(".main-child-sub ")
        var menuSubSub = document.querySelectorAll(".main-child-sub-sub");
        hamburger.forEach((i) => {
            i.classList.remove("menu-open");
            gsap.to(menuRef.current, {
                opacity: 0, duration: ".2",
            });
            gsap.to(menuRef.current, {
                display: "none",
            });
        });
        menuOpen.forEach((i) => {
            i.classList.remove("menu-open");

        });

        menu.forEach((i) => {
            i.classList.remove("submenu-open-mb");

        });
        menuSub.forEach((i) => {
            i.classList.remove("submenu-open-next");

        });
        menuSubSub.forEach((i) => {
            i.classList.remove("submenu-open-next-next");
            i.classList.remove("submenu-open-next");
            i.classList.remove("submenu-open-mb");

        });
    }
    const handleCloseClick = (e) => {

        remove_all_mobile()
    };


    // menu functionalities
    useEffect(() => {

        const body = document.body;
        const scrollUp = "scroll-up";
        const scrollDown = "scroll-down";



        // menu click on mobile
        hamburgerRef.current.addEventListener("click", () => {
            document.querySelector(".hamburger").classList.add("menu-open");
            document.querySelector(".Mobile-menu-wrap").classList.add("menu-open");
            gsap.to(menuRef.current, {
                display: "block", duration: "0.5", x: '100%',
            });
            gsap.to(menuRef.current, {
                x: 0, opacity: 1, duration: "0.5",
            });
            const target = document.querySelectorAll('.Mobile-menu-wrap')
            // disableBodyScroll(target);

        });
        hamburgerCloseRef.current.addEventListener("click", () => {
            document.querySelector(".hamburger").classList.remove("menu-open");
            document.querySelector(".Mobile-menu-wrap").classList.remove("menu-open");
            gsap.to(menuRef.current, {

                opacity: 0, x: '100%', duration: "0.5",
            });
            gsap.to(menuRef.current, {

                opacity: 0, duration: "0.2",
            });
        });
        // Mobile menu end

        //---- on scroll menu fixed action
        if (document.body.classList.contains("scroll-down")) {
            document.body.classList.remove("scroll-down");
        }


    }, []);

    // menu fixed on scroll
    useEffect(() => {
        if (document.body.classList.contains('scroll-down')) {
            document.body.classList.remove('scroll-down');
        }
    });

    // sticky menu
    useEffect(() => {
        const body = document.body;
        const scrollUp = 'scroll-up';
        const scrollDown = 'scroll-down';
        let lastScroll = 0;
        let howMuchScroll = 150;
        if (window.screen.width < 991) {
            howMuchScroll = 0;
        } else {
            howMuchScroll = 150;
        }

        window.addEventListener('scroll', () => {
            let currentScroll = window.pageYOffset;

            if (currentScroll <= howMuchScroll) {
                body.classList.remove(scrollUp);
                return;
            }
            if (currentScroll > lastScroll && !body.classList.contains(scrollDown)) {
                // down
                body.classList.remove(scrollUp);
                body.classList.add(scrollDown);
            } else if (currentScroll < lastScroll && body.classList.contains(scrollDown)) {
                // up
                body.classList.remove(scrollDown);
                body.classList.add(scrollUp);
            }
            lastScroll = currentScroll;
        });
    }, []);


    const [activeMenuItem, setActiveMenuItem] = useState('');

    const handleMenuClick = (menuId) => {
        // Set the clicked menu item as active
        setActiveMenuItem(menuId);
    };

    const menuItems = [
        { id: 'info', label: 'ABOUT' },
        { id: 'skills', label: 'SKILLS' },
        { id: 'experience', label: 'EXPERIENCE' },
        { id: 'blogs', label: 'BLOGS' },
        { id: 'contacts', label: 'CONTACTS' },
    ]


    return (<StyledComponent className="Mobile-menu-wrap menu-bar">
        <Container>
            <Row className="mobile-menu" ref={mobileMenuRaf}>

                {/*logo*/}
                <Col className="logo forNormal">
                    <Link to={"/"}>
                       <h3>{data?.posts?.site_name}</h3>
                    </Link>
                </Col>

                {/*close icon*/}
                <Col className="logo forClose">
                    <Link to={"/"}>
                        <h3>{data?.posts?.site_name}</h3>
                    </Link>
                </Col>

                {/*hamburger logo and close icon*/}
                <Col className="hamburger">
                    <span ref={hamburgerRef} className={'mobile-sticky'}>
                              <HamburgerMobile/>
                          </span>
                    <span className={'close-icon'} ref={hamburgerCloseRef}>
                    <CloseIconMobile/>
                     </span>
                </Col>

                {/*menu item*/}
                <div className="mobile-menu__items" ref={menuRef}>
                    <ul className="mobile-menu__items__ul">
                        <Accordion defaultActiveKey="">

                            {menuItems.map((menuItem) => (
                                <li
                                    key={menuItem.id}
                                    className={menuItem.id === activeMenuItem ? 'active' : ''}
                                >
                                    <a href={`#${menuItem.id}`} onClick={() => {
                                        handleMenuClick(menuItem.id)
                                        handleCloseClick()
                                    }}>
                                        {menuItem.label}
                                    </a>
                                </li>
                            ))}

                        </Accordion>
                    </ul>
                    
                </div>

            </Row>

        </Container>
    </StyledComponent>);
};

const StyledComponent = styled.section`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 90px;
  z-index: 99999;
  display: flex;
  align-items: center;
  transition: background .4s cubic-bezier(0.4, 0, 0, 1);
  overflow: visible !important;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background-color: rgba(7,7,7,.5);
  //container
  .container {
    background-color: transparent;
    overflow: hidden;
    transition: all 1.2s cubic-bezier(0.4, 0, 0, 1) 0s;
  }
  
  //menu open
  &.menu-open {
    transform: unset !important;
    transition: unset;

    .container {
      background-color: ${black};
      overflow: hidden;
      transition: all 1.2s cubic-bezier(0.4, 0, 0, 1) 0s;
      @media (min-width: 768px) and (max-width: 991px) {
        max-width: 100% !important;
        padding-left: 7%;
        padding-right: 7%;
      }
    }

    .forNormal {
      display: none !important;
    }

    .forClose {
      display: flex !important;
    }
  }
  

  
  //menu bar
  .menu-bar {
    background-color: transparent !important;
    box-shadow: unset !important;
  }
  
  //close icon
  .forClose {
    display: none !important;

    a {
      img {
        height: 50px !important;
      }
    }
  }

  //menu sticky css
  .mobile-sticky {
    .hamburger-icon {
      display: inline-block;
      cursor: pointer;
      width: 30px;
      height: 16px;
      position: relative;
      margin: 3px 0px 0px 23px;
    }

    .hamburger-icon span {
      display: block;
      position: absolute;
      height: 2px;
      width: 110%;
      background: ${white};
      transition: transform 0.3s ease;


      &:nth-child(1) {
        top: 0;
        width: 75%;
        right: 0;
        transition: width .3s ease;
      }

      &:nth-child(2) {
        display: block !important;
        right: 0;
        top: 50%;
        margin-top: -1px;
        transition: width .3s ease;
      }

      &:nth-child(3) {
        bottom: 0;
        width: 75%;
        right: 0;
        transition: width .3s ease;
      }

    }

    /* Animation for the active/hamburger open state */

    .hamburger-icon:hover span:nth-child(1) {
      width: 110%;
      transition: width .3s ease;
    }

    .hamburger-icon:hover span:nth-child(2) {
      display: block;
      width: 75%;
      transition: width .3s ease;
    }

    .hamburger-icon:hover span:nth-child(3) {
      width: 110%;
      transition: width .3s ease;
    }
  }
  
  //mobile menu
  .mobile-menu {
    .main_child a svg {
      z-index: -1;
    }

    .mobile-menu__items {
      overflow: visible;
      overflow-x: clip;
      overflow-y: scroll;
    }

    height: 90px;

    .logo {
      display: flex;
      align-items: center;

      span {
        width: 220px !important;
      }

      a {
        img {
          width: 119px;
          height: 50px;
        }
      }

    }

    .hamburger {
      position: relative;
      display: flex;
      align-items: center;

      span {
        position: absolute !important;
        right: 10px !important;
        display: flex;

        p {
          font-size: 16px;
          font-weight: 400;
          line-height: 20px;
          color: ${white};
          margin: 0 10px 0 0;
        }
      }

      .close-icon {
        display: none !important;
      }

      &.menu-open span {
        &:nth-of-type(1) {
          display: none !important;
        }

        &:nth-of-type(2) {
          display: flex !important;
          right: 20px !important;
        }
      }
    }

    &__items {

      display: none;
      position: absolute;
      opacity: 0;
      -webkit-backdrop-filter: blur(10px);
      backdrop-filter: blur(10px);
      background-color: rgba(7,7,7,.5);
      background-color: ${black};
      height: calc(100vh - 90px);
      top: 90px;
      left: 0;
      width: 100%;
      z-index: 99;
      padding: 55px 15px 60px 15px;
      overflow: auto;
      transition: opacity .1s cubic-bezier(0.4, 0, 0, 1);

      &:after {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        background: ${white};
        height: 1px;
        content: "";
        margin: 0 auto;
        width: 100%;
      }

      &__top {
        ul {
          li {
            a {
              height: 45px;
              border-radius: 25px;
              background-color: ${hover};
              color: ${white};
              font-size: 18px;
              font-weight: 400;
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: flex-start;
              padding: 0 30px;
              text-transform: capitalize;

              margin-bottom: 30px;

              img {
                margin-right: 10px;
              }

              span {
                margin-right: 10px !important;
              }
            }

            &:nth-last-child(1) a {
              margin-bottom: 60px;
              background-color: #4F616B;

              &:hover {
                color: ${white} !important;
              }
            }

          }
        }
      }

      &__ul {

        li {
          padding-bottom: 25px;
          border-bottom: 1px solid white;
          margin-bottom: 25px;

          &:nth-last-child(1) {
            border-bottom: 0;
          }
          
          &.active{
            a{
              color: ${hover};

            }
          }

          a {
            color: ${white};
            font-weight: 500;
            font-size: 20px;
            line-height: 20px;
            text-transform: capitalize;
          }
        }

      }

    }

    &.menu-open {

      .mobile-menu__items {
        display: block;
      }

    }


    .main-child-sub {
      a {
        display: flex;
        justify-content: space-between;
      }
    }

    //accordion
    .accordion-item {
      .accordion-header {
        .accordion-button {
          background-color: transparent;
          color: ${white};
          font-weight: 500;
          font-size: 20px;
          line-height: 20px;
          text-transform: capitalize;
          width: 100%;
          text-align: left;
          display: flex;
          justify-content: space-between;
          padding: 0 0 25px;
          box-shadow: none;
          border: none;
          margin-bottom: 20px;
          border-bottom: 1px solid ${white};

          p {
            margin: 0;
            font-size: 22px;
            line-height: 28px;
            text-transform: capitalize;
            font-weight: bold;
            padding-right: 30px;
            width: 100%;
          }

          span {
            &:nth-last-child(2) {
              display: none !important;
            }

            &:nth-last-child(1) {
              display: block !important;
            }
          }


          &.collapsed {
            span {
              &:nth-last-child(2) {
                display: block !important;
              }

              &:nth-last-child(1) {
                display: none !important;
              }
            }
          }


          &.collapsed {
            border-bottom: 1px solid ${white};
          }
        }


      }

      .accordion-body {
        padding-bottom: 25px;
        padding-top: 20px;

        ul {
          li {
            border: none;
            padding-bottom: 0;
            margin-bottom: 0;

            a {
              font-size: 16px;
              line-height: 22px;
              font-weight: 600;
              margin-bottom: 15px;
              display: block;
            }

            &:after {
              display: none;
            }
          }
        }
      }

    }

    .social {
      &__icon {
        position: absolute;
        bottom: unset;
        display: flex;
        &__icons {
          margin-right: 20px;
        }
      }
    }

    .main_child {
      border: none;
      padding-bottom: 0;

      a {
        background-color: transparent;
        color: ${white};
        font-weight: 500;
        font-size: 20px;
        line-height: 20px;
        text-transform: capitalize;
        width: 100%;
        text-align: left;
        display: flex;
        justify-content: space-between;
        padding: 0 0 0 25px;
        box-shadow: none;
        border: none;
        margin-bottom: 20px;
        border-bottom: 1px solid ${primary};

        @media (max-width: 767px) {
          img {
            z-index: -1;
            position: relative;
          }
        }

        &:hover {
          color: ${white} !important;
          opacity: 70%;
        }

        p {
          margin: 0;
          font-size: 22px;
          line-height: 28px;
          text-transform: capitalize;
          font-weight: bold;
          padding-right: 30px;
          width: 100%;
        }

        span {
          &:nth-last-child(2) {
            display: none !important;
          }

          &:nth-last-child(1) {
            display: block !important;
          }
        }


        &.collapsed {
          span {
            &:nth-last-child(2) {
              display: block !important;
            }

            &:nth-last-child(1) {
              display: none !important;
            }
          }


        }


        &.collapsed {
          border-bottom: 1px solid ${white};
        }
      }

      &.submenu-open-mb {
        .submenu {
          opacity: 1;
          visibility: visible;
          transform: translateX(0);


        }

        .submenu-open-next {
          .mega_sub {
            opacity: 1;
            visibility: visible;
            transform: translateX(0);
          }

        }

        .submenu-open-next-next {
          .mega_sub_sub {
            opacity: 1;
            visibility: visible;
            transform: translateX(0);
          }

        }
      }

      .submenu {
        margin: 55px 15px 30px;
        position: fixed;
        right: 0;
        top: 0;
        left: 0;
        bottom: 0;
        background: ${black};
        width: 100%;
        opacity: 0;
        visibility: hidden;
        transition: 0.7s all cubic-bezier(0.4, 0, 0, 1);
        transform: translateX(100%);
        overflow-x: scroll;


        &.sub-menu-open {
          opacity: 1;
          visibility: visible;
          transform: translateX(0);

        }

        &::-webkit-scrollbar {
          display: none;
        }

        .main_title {
          font-size: 20px;
          line-height: 24px;
          text-align: left;
          display: flex;
          color: ${white};
          margin-bottom: 60px;
          font-weight: 400;

          svg {
            margin-right: 20px;
            position: relative;
            z-index: -1;
          }
        }

        li {
          border: none;
          padding-bottom: 0;
          margin-bottom: 0;

          a {
            color: ${white};
            font-size: 20px;
            line-height: 24px;
            font-weight: 400;
            margin-bottom: 25px;
          }

          &:after {
            display: none;
          }
        }

        .mega_sub {
          padding: 20px 15px 30px;
          position: fixed;
          right: 0;
          top: 0px;
          left: 0;
          bottom: 0;
          background: ${white};
          width: 100%;
          opacity: 0;
          visibility: hidden;
          transition: 0.7s all cubic-bezier(0.4, 0, 0, 1);
          transform: translateX(100%);
          height: 185vh;
        }

        .mega_sub_sub {
          padding: 20px 15px 30px;
          position: fixed;
          right: 0;
          top: 0px;
          left: 0;
          bottom: 0;
          background: ${black};
          z-index: 999;
          width: 100%;
          opacity: 0;
          visibility: hidden;
          transition: 0.7s all cubic-bezier(0.4, 0, 0, 1);
          transform: translateX(100%);
        }


      }

      .fotter_menu {
        margin-top: 150px;

        h3 {
          font-size: 32px;
          color: ${white};
          line-height: 32px;
        }

        p {
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          color: ${white};
        }

        .footer {
          margin-top: 40px;

          a {
            font-size: 24px;
            font-weight: 400;
            line-height: 28px;
            color: ${white};
            padding: 0;
            display: block;
            margin: 0 0 10px;
            border: none;

            &:last-child {
              margin: 0;
            }
          }
        }
      }

   

    }

  }

  //menu bottom
  .mobile-bottom-bar__buttons {
    padding-left: 40px;
    padding-right: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;

    li {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  //menu bottom bar 
  .mobile-bottom-bar__account {
    position: fixed;
    width: 100%;
    padding: 110px 20px 60px 20px;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: ${white};
    box-shadow: 0 0 5px rgba(255, 255, 255, 0.20);
    visibility: hidden;
    transform: translateY(100%);
    transition: all .6s cubic-bezier(0.87, 0, 0.13, 1);

    svg {
      position: absolute;
      right: 13px;
      top: 50px;
      font-size: 40px;
      color: black;
    }

    p {
      font-size: 16px;
      color: #D0CA10;
      line-height: 22px;
      margin-bottom: 40px;
      font-weight: 600;
    }

    ul {
      width: 100%;

      li {
        a {
          display: flex;
          font-size: 12px;
          line-height: 18px;
          font-weight: 600;
          padding-bottom: 20px;
          border-bottom: 1px solid rgba(255, 255, 255, 0.1);
          margin-bottom: 20px;
        }

        &:nth-last-of-type(1) {
          a {
            margin-bottom: 0;
            border-bottom: 0;
          }
        }
      }
    }

    &.open {
      transform: none;
      visibility: visible;
    }
  }

  //sub menu styel
  .has-child-sub.open_submenu > a svg {
    opacity: 1;
    right: 0;
  }

  //sub menu hover 
  .has-child-sub-sub a:hover {
    svg {
      opacity: 1 !important;
    }
  }




  @media (min-width: 992px) {
    display: none;
  }
`;

export default MyComponent;
