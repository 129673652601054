// Steven Theme style


// #text animation fade up style
// #global style
// ##font family style
// ##Typograpphy and body common style
// ##Firefox
// ##react select
// ##preloader
// ##open to work button
// ##tab
// ##pagination
// ##form
// ##sticky menu style
// ##blog single
// ##big list style
// ##table style
// ##comments
// ##progressbar

// ################################



import {createGlobalStyle, css} from 'styled-components';
import {black, hover, primary, red, secondary, text, title, white} from './globalStyleVars';





// text animation fade up style
function createCSS() {
    let styles = '';

    for (let i = 2; i < 20; i += 1) {
        styles += `
        .anim-active.fade-up:nth-child(${i}) {
          transition-delay: ${i * .12}s;
        }
     `
    }

    for (let a = 2; a < 100; a += 1) {
        styles += `
        .anim-active.fade-right span:nth-child(${a}) {
          transition-delay: ${a * .03}s;
        }
     `
    }

    return css`${styles}`;
}



// global style
export default createGlobalStyle`

  ${createCSS()}
  #root {
    min-height: 100vh;
    overflow-x: hidden;
  }


  //font family style
  @font-face {
    font-family: 'Neue Haas Grotesk Display Pro';
    src: url('/fonts/NeueHaasDisplay-Roman.woff2') format('woff2'),
    url('/fonts/NeueHaasDisplay-Roman.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Mango Grotesque Med';
    src: url('/fonts/MangoGrotesque-Medium.woff2') format('woff2'),
    url('/fonts/MangoGrotesque-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }


  //Typograpphy and body
  html{
    scroll-behavior: smooth;
  }
  body {
    font-family: 'Neue Haas Grotesk Display Pro', Arial, Helvetica, freesans, sans-serif !important;
    font-style: normal;
    margin: 0;
    color: ${white};
    padding: 0;
    overflow-x: hidden;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    min-height: 100vh;
    font-size: 16px;
    line-height: 24px;
    background-color: ${black};
    scroll-behavior: smooth;
    /* width */

    ::-webkit-scrollbar {
      width: 5px;
    }

    /* Track */

    ::-webkit-scrollbar-track {
      background: transparent;

    }

    /* Handle */

    ::-webkit-scrollbar-thumb {
      background: ${primary};
    }

    /* Handle on hover */

    ::-webkit-scrollbar-thumb:hover {
      //background: transparent;
    }
  }

  h1, h2, h3 {
    font-family: 'Mango Grotesque Med', Arial, Helvetica, freesans, sans-serif !important;
  }



  h1 {
    font-size: 90px;
    font-weight: 500;
    line-height: 90px;
    letter-spacing: 0.5px;
    @media (min-width: 1800px) {
      font-size: 90px;
      line-height: 90px;
    }
    @media (max-width: 767px) {
      font-size: 42px;
      line-height: 55px;
    }
  }

  h2 {
    font-size: 80px;
    font-weight: 500;
    line-height: 80px;
    letter-spacing: 0.5px;
    @media (min-width: 1800px) {
      font-size: 90px;
      line-height: 90px;
    }
    @media (max-width: 767px) {
      font-size: 40px;
      line-height: 45px;
    }
  }

  h3 {
    font-size: 50px;
    font-weight: 500;
    line-height: 65px;
    letter-spacing: 0.5px;
    @media (min-width: 1800px) {
      font-size: 50px;
      line-height: 65px;
    }
    @media (max-width: 767px) {
      font-size: 30px;
      line-height: 40px;
    }
  }


  h4 {
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    @media (min-width: 1800px) {
      font-size: 18px;
      line-height: 27px;
    }
    @media (max-width: 767px) {
      font-size: 18px;
      line-height: 27px;
    }
  }


  h5 {
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0.2px;
    @media (min-width: 1800px) {
      font-size: 16px;
      line-height: 24px;
    }
    @media (max-width: 767px) {
      font-size: 14px;
      line-height: 21px;
    }
  }

  h6 {
    font-size: 12px;
    font-weight: 500;
    line-height: 21px;
    @media (min-width: 1800px) {
      font-size: 14px;
      line-height: 21px;
    }
    @media (max-width: 767px) {
      font-size: 12px;
      line-height: 21px;
    }
  }


  .p1-font {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;

  }

  .menu-font {
    font-size: 15px; /* 15px / 15px = 1rem */
    font-weight: 600;
    line-height: 20px; /* 20px / 15px = 1.3333rem */
  }


  .meta-font {
    font-size: 15px; /* 15px / 15px = 1rem */
    font-weight: 600;
    line-height: 20px; /* 20px / 15px = 1.3333rem */
  }


  a {
    transition: color .3s ease;
    text-decoration: none;

    &:hover {
      color: ${hover} !important;
      text-decoration: none;
      outline: none;
      box-shadow: none;
    }

    &:focus {
      text-decoration: none;
      outline: none;
      box-shadow: none;
      color: ${text};
    }
  }

  ::selection {
    background: ${hover};
    color: ${white};
  }

  p, a, h4, h3, h5, h6 {
    color: ${white};
    font-weight: 400;
    margin: 0;
  }

  h1, h2 {
      //font-family: ${title};
    margin: 0;
  }

  ul {
    margin: 0;
    padding: 0
  }

  li {
    list-style: none;
  }

  img {
    max-width: 100%;
    object-fit: contain;
  }


  .btn:focus, button:focus, button:active:focus, .btn.active.focus, .btn.active:focus, .btn.focus, .btn:active.focus, .btn:active:focus, .btn:focus {
    outline: none;
    box-shadow: none;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    border: 1px solid rgba(0, 0, 0, 0);
    -webkit-text-fill-color: #000;
    -webkit-box-shadow: 0 0 0px 1000px rgba(0, 0, 0, 0) inset;
    transition: background-color 5000s ease-in-out 0s;
  }


  table {
    width: 100%;
  }

  form div {
    position: relative;
  }

  .form-control {
    box-shadow: none;
    outline: 0;
    border-radius: 0;

    &:focus {
      box-shadow: none;
    }
  }

  .error-message {
      font-size: 12px;
      font-weight: 300;
      letter-spacing: -0.2px;
      color: #FF0000;
  }

  .p-0 {
    padding: 0 !important;
  }

  .pl-0 {
    padding-left: 0;
  }

  .pr-0 {
    padding-right: 0;
  }

  .pt-150 {
    padding-top: 150px;
    @media (max-width: 1000px) {
      padding-top: 90px;
    }
  }

  .pt-80 {
    padding-top: 80px;
    @media (max-width: 767px) {
      padding-top: 80px;
    }
  }

  .pb-150 {
    padding-bottom: 150px;
    @media (max-width: 1000px) {
      padding-bottom: 90px;
    }
  }

  .pt-160 {
    padding-top: 160px;
    @media (max-width: 767px) {
      padding-top: 100px;
    }
  }

  .pb-160 {
    padding-bottom: 160px;
    @media (max-width: 767px) {
      padding-bottom: 100px;
    }
  }

  .pb-130 {
    padding-bottom: 130px;
    @media (max-width: 767px) {
      padding-bottom: 100px;
    }
  }

  .pt-100 {
    padding-top: 100px;
    @media (max-width: 767px) {
      padding-top: 60px;
    }
  }

  .pb-100 {
    padding-bottom: 100px;
    @media (max-width: 767px) {
      padding-bottom: 60px;
    }
  }

  .pt-80 {
    padding-top: 80px;
    @media (max-width: 767px) {
      padding-top: 40px;
    }
  }

  .pb-80 {
    padding-bottom: 80px;
    @media (max-width: 767px) {
      padding-bottom: 40px;
    }
  }


  .MuiDrawer-paper {
    width: 500px !important;
    padding: 20px;
    @media (max-width: 767px) {
      width: 100% !important;
    }
  }

  .swiper-button-disabled {
    opacity: 0 !important;
  }

  @media (min-width: 1500px) {
    .container {
      //min-width: 1366px;
      min-width: 85%;
      margin: auto;
    }
  }

  @media (max-width: 1199px) and (min-width: 768px) {
    .container, .container-lg, .container-md, .container-sm {
      max-width: 90%;
      margin: auto;
    }
  }


  @media (max-width: 767px) {
    .container, .container-sm {
      max-width: 100%;
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }


  //react select
  .css-yk16xz-control, .css-1pahdxg-control {
    height: 50px;
    border-radius: 0 !important;
    padding-left: 5px;
    font-size: 16px;
    outline: none !important;
    border-color: #D9D9D9 !important;
    box-shadow: none !important;

    .css-1wa3eu0-placeholder {
      font-weight: 300;
      line-height: 21px;
      color: rgba(0, 0, 0, 0.5);
      outline: none;
    }

    .css-1okebmr-indicatorSeparator {
      display: none;
    }

    .css-tlfecz-indicatorContainer, .css-1gtu0rj-indicatorContainer {
      margin-right: 10px;
    }
  }

  .css-2613qy-menu {
    border-radius: 0 !important;
    margin-top: 0 !important;
  }

  //animation class

  .info-window {
    max-width: 200px;
  }

  .gm-style-iw {
    border-radius: 0 !important;
  }

  .swiper-pagination-bullet {
    outline: none;
  }

  .css-nmuc1a-menu {
    z-index: 5 !important;
  }


  .map-info__img {
    img {
      height: 100px;
      margin-bottom: 12px;
      object-fit: cover;
    }
  }

  .map-info__content {
    h4 {
      font-size: 20px;
    }

    p {
      margin-bottom: 5px;
    }
  }

  

  .form-control.has-error {
    border-color: ${red} !important;
  }

  .has-error .find-retainer-filter__control {
    border-color: ${red} !important;
  }

  //preloader
  .content-loader {
    position: absolute;
    height: 70%;
    width: 70%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }

  .loading-before-submit {
    position: fixed;
    height: 100vh;
    width: 100%;
    bottom: 0;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.65);
    z-index: 9;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      height: 40px;
    }
  }


  .swiper-slide {
    height: auto;
  }

  .slick-slide {
    div {
      outline: none !important
    }
  }

  button, button:active, button:focus, button:focus-visible {
    outline: none !important;
    box-shadow: none !important;
  }





  .modal-backdrop {
    background-color: rgb(34 31 31 / 90%);
    min-width: 100%;
    //z-index: 9999;

    &.show {
      opacity: 1;
    }
  }


  .valid {
    color: ${hover};
    position: absolute;
    font-size: 12px;
    top: 44px;
  }

  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }


  //menu fixed
  .scroll-down .menu-desktop {
    transform: translate3d(0, -100%, 0);
  }

  .scroll-up .menu-desktop {
    background-color: ${white};

    .menu-desktop__hamburger__lines {
      &__inner .line {
        background-color: #191818;
      }

      p {
        color: #191818;
      }
    }

    .menu-desktop__hamburger .controller svg {
      path {
        fill: #191818;
      }

      line {
        stroke: #191818;
      }
    }

    .dc-btn a {
      color: #191818;

      &:hover {
        color: #191818 !important;
      }

      &:after, &:before {
        box-shadow: 0 0 0 1px #191818;
      }
    }
  }

  .form-control:disabled {
    background-color: transparent;
  }

  @media (max-width: 600px) {
    .prevent-overflow {
      overflow: hidden;
      height: 100vh;
    }
  }

  .Toastify__toast-container {
    z-index: 99999999;
  }

  .mobile-menu {
    #fb-root, .fb_reset {
      display: none !important;
      opacity: 0 !important;
      visibility: hidden !important;
    }
  }

  .slick-slide {
    -webkit-transform: translate3d(0, 0, 0);
  }

  .reveal, .revealFast {
    overflow: hidden;
    height: 100%;
    width: 100%;
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;

    img {
      transform-origin: left;
    }

    .global-image {
      background: transparent;
    }
  }

  //calender
  ._3efP_GeH5kyBAzqnLzL._kN_bCa3VNYpqFLH311L {
    border-radius: 0 !important;
  }

  //video modal 
  .modal-video-close-btn:before, .modal-video-close-btn:after {
    background-color: ${hover};
  }

  .page-loader {
    position: fixed;
    background-color: rgb(36, 24, 67);
    width: 100vw;
    z-index: 999999999;
    overflow: hidden;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
 
    .pre-loader__img {
      height: 100px;
      width: 100px;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: rgb(36, 24, 67);
      z-index: 99999999;

      //flex: 1;
      margin: auto;
      opacity: 0;

      img {
        display: block;
      }

      .top {
        height: 8px;
      }

      .bottom {
        height: 30px;
        margin-top: 5px;
        animation: flip 1s linear infinite;
      }

      @keyframes flip {
        0% {
          transform: rotateY(0deg)
        }
        100% {
          transform: rotateY(180deg)
        }
      }
    }

  }

  //------------------------animation
  //text animation 
  .split-parent {
    overflow: hidden;
  }

  .split-child {
    overflow: hidden;
  }

  .reveal {
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    img {
      transform-origin: left;
    }

    .global-image {
      background: transparent;
    }
  }


  //open to work button 
  .open-to-work-button {
    position: fixed;
    bottom: 4rem;
    right: 70px;
    z-index: 2;

    a {
      background: ${white}fff80;
      -webkit-backdrop-filter: blur(1rem);
      backdrop-filter: blur(1rem);
      border-radius: 30px;
      color: #000;
      cursor: pointer;
      font-size: 12px;
      letter-spacing: 0.5px;
      font-weight: 500;
      height: 30px;
      display: flex;
      align-items: center;
      padding: 0 15px;
      text-transform: uppercase;
      transition: all .4s cubic-bezier(.7, 0, .3, 1);

      span {
        transition: color .3s ease;
        color: ${white};
        position: relative;
        z-index: 2;
      }


      &:before {
        bottom: 0;
        content: "";
        display: block;
        position: absolute;
        right: 0;
        top: 0;
        left: 0;
        background-color: ${primary};
        height: 0%;
        width: 0%;
        margin: auto;
        transition: all .5s ease;
        border-radius: 22px;
      }


      &:hover {
        &:before {
          height: 100%;
          width: 100%;
        }

        span {
          color: ${white};
        }
      }

      &:last-child {
        margin-top: 10px;
      }
    }
    
    @media(max-width: 767px){
      right: 20px;
    }
  }


  //tab 
  .react-tabs__tab-list {
    margin-bottom: 40px !important;
    border-bottom: none !important;
  }

  .react-tabs__tab--selected {
    background: ${primary} !important;
    color: ${white} !important;
  }

  .react-tabs__tab {
    border: none !important;
    border-radius: 20px !important;
    padding: 10px 20px !important;
    font-weight: 500 !important;
    font-size: 16px;

    &:after, &:before {
      display: none !important;
    }
  }


  //pagination 
  .pagination {
    margin-top: 40px;

    li {
      border: 1px solid white;
      border-radius: 5px;
      margin-right: 5px;
      transition: 0.7s all ease;
      position: relative;

      &:last-child {
        margin-right: 0;
      }

      a {
        font-weight: 500;
        width: 40px;
        height: 40px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        color: ${white} !important;
      }

      &.active {
        background: ${primary};
        border: 1px solid ${primary};
      }

      &:hover {
        background: ${primary};
        border: 1px solid ${primary};

        a {
          color: ${white} !important;
        }
      }
    }
  }


  //form
  form {
    .form-control {
      background: transparent;
      border-left: none !important;
      border-right: none !important;
      border-top: none !important;
      margin-bottom: 20px;
      padding-bottom: 20px;

      &:focus {
        background: transparent;
        border-left: none !important;
        border-right: none !important;
        border-top: none !important;
        margin-bottom: 30px;
        padding-bottom: 20px;
        border-color: #495057 !important;
      }
    }
  }


  
  
  //sticky menu style 
  body.scroll-down .main-menu-desktop {
    transform: translate3d(0, -100%, 0);
    transition: 0.7s all ease;
  }

  body.details-page .main-menu-desktop {
    background-color: rgba(7, 7, 7, .5);
    transition: 0.7s all ease;
    backdrop-filter: blur(5px);
  }

  body.scroll-up .main-menu-desktop {
    background-color: rgba(7, 7, 7, .5);
    transition: 0.7s all ease;
    backdrop-filter: blur(5px);
  }

  body.scroll-down .Mobile-menu-wrap {
    transform: translate3d(0, -100%, 0);
    transition: 0.7s all ease;
  }

  body.details-page .Mobile-menu-wrap {
    // background-color: '';
    transition: 0.7s all ease;


  }

  body.scroll-up .Mobile-menu-wrap {
    // background-color: '';
    transition: 0.7s all ease;


  }


  //blog single 
  .small-list {
    margin: 60px 0;

    li {
      position: relative;
      padding-bottom: 20px;
      margin-bottom: 20px;
      border-bottom: 1px solid ${white};
      padding-left: 28px;

      &:before {
        position: absolute;
        left: 0;
        bottom: 0;
        right: 0;
        content: '';
        background: ${white};
        height: 1px;
        width: 100%;
      }

      &:after {
        position: absolute;
        left: 0;
        top: 5px;
        content: '';
        background: ${secondary};
        height: 8px;
        width: 8px;
        border-radius: 50%;
      }

      &:last-child {
        margin-bottom: 0;
        border-bottom: none;

        &:before {
          display: none;
        }
      }
    }
  }


  
  //big list style
  .big-list {

    li {
      counter-increment: count 1;
      position: relative;
      font-size: 24px;
      font-weight: 600;
      padding-bottom: 20px;
      margin-bottom: 20px;
      border-bottom: 1px solid ${white};
      padding-left: 50px;

      &:after {
        content: counter(count, upper-alpha);
        position: absolute;
        height: 30px;
        width: 30px;
        padding: 0px;
        background: ${secondary};
        border-radius: 50%;
        top: 5px;
        left: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        font-weight: 500;
        line-height: 18px;
        color: ${white}fff;
      }

      &:not(:nth-last-child(1)) {
        margin-bottom: 25px;
      }

      p {
        font-size: 16px;
        line-height: 20px;
        color: ${text};
        margin-top: 20px;
      }

      &:nth-last-child(1) {
        border-bottom: 0;
        padding-bottom: 0;
        margin-bottom: 0;
      }
    }
  }

  //table style
  table {
    gap: 20px;

    th {
      font-size: 1.3333rem; /* 20px / 15px = 1.3333rem */
      font-weight: 500;
      line-height: 1.8667rem; /* 28px / 15px = 1.8667rem */
      color: ${white};
      border-top: none !important;
    }

    tr {
      border-bottom: 1px solid ${white};
      justify-content: flex-start;
      padding: 20px 0;
      display: flex;
      flex-wrap: wrap;
      gap: 30px;

      &:first-child {
        border-bottom: 1px solid ${white};
      }

      &:last-child {
        border-bottom: none;
      }

      th {
        max-width: calc(50% - 30px);
        flex: 0 0 calc(50% - 30px);
      }

      td {
        max-width: calc(50% - 30px);
        flex: 0 0 calc(50% - 30px);
      }
    }
  }

  blockquote {
    display: block;
    border-left: 4px solid ${hover};
    padding-left: 20px;

    span {
      display: block;
      margin-top: 40px;
    }
  }
  
  
  //comments
  .comments-wrapper {
    padding-bottom: 80px;
    .overlay{
      padding: 0 !important;
      font-family:inherit !important;
    }
    .userImg{
      width: 45px;

    }
    .postBtn{
        background: ${secondary};
      border: 2px solid ${secondary};
      &:hover{
        background: ${primary};
        border: 2px solid ${primary};
      }
    }
    .imgdefault {
      width: 35px;
      height: 35px;
      border-radius: 50%;
    }
    .comment-title{
      padding: 0 !important;
      color:${white};
      font-family:"Mango Grotesque Med", Arial, Helvetica, freesans, sans-serif  !important;
      font-size: 50px;
      font-weight: 500;
      line-height: 65px;
      letter-spacing: 0.2px;
      @media (min-width: 1800px) {
        font-size: 50px;
        line-height: 65px;
      }
      @media (max-width: 767px) {
        font-size: 30px;
        line-height: 40px;
      }
    }
  }

  .form-control.has-error {
      border-color: #dc004e !important;
  }

  .has-error .find-retainer-filter__control {
      border-color: #dc004e !important;
  }


  //progressbar 
  .progress-bar{
    position:fixed;
    top:0;
    right:0;
    left: 0;
    z-index: 99;
    width:100%;
    div{
      position:fixed;
      top:0;
      right:0;
      left: 0;
      z-index: 1;
    }
  }

  form div {
      position: relative;
  }

  
`;



