import React from 'react';
import styled from 'styled-components';
import {hover, primary} from "../styles/globalStyleVars";
import {Link} from "react-router-dom";


const Background = () => {
    return (
        <StyledBackground className={``}>
            <div className="bg-grid">
                <div className="d-block">
                    <div className="wrap-grid-ver">
                        <div className="vertical-line-grid gradient"></div>
                        <div className="vertical-line-grid gradient"></div>
                        <div className="vertical-line-grid gradient"></div>
                        <div className="vertical-line-grid gradient"></div>
                        <div className="vertical-line-grid gradient"></div>
                        <div className="vertical-line-grid gradient"></div>
                        <div className="vertical-line-grid gradient"></div>
                        <div className="vertical-line-grid gradient"></div>
                        <div className="vertical-line-grid gradient"></div>
                    </div>
                </div>
            </div>
            <div className="background-strip"></div>
        </StyledBackground>
    )
};

const StyledBackground = styled.div`
  .bg-grid {
    z-index: -1;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    .d-block {
      height: 100vh;
      margin-left: auto;
      margin-right: auto;
      padding-left: 80px;
      padding-right: 80px;

      .wrap-grid-ver {
        width: 100%;
        height: 100%;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        margin-left: auto;
        margin-right: auto;
        padding-top: 70px;
        display: flex;
        position: relative;

        .vertical-line-grid.gradient {
          mix-blend-mode: multiply;

          background-color: rgba(39, 39, 39, 0);
          background-image: linear-gradient(356deg, #272727 50%, rgba(39, 39, 39, 0));
        }

        .vertical-line-grid {
          width: 1px;
          height: 100%;
          background-color: var(--dark-grey);
          padding-right: 0;
        }
      }
    }
  }

  .background-strip {
    z-index: -1;
    position: fixed;
    right: 120px;
    width: 300px;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    background: rgba(238, 125, 61, 0.56);
    height: 100%;
    top: 0;
    bottom: 0;
    @media (max-width: 1000px) {
      width: 20vh;
      right: 30px;
    }

    @media (max-width: 767px) {
      right: 35px;
    }
  }
`;


export default React.memo(Background);
