import React, {useEffect} from 'react';
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import {Img} from "../../../components/Img";
import gsap, {CSSPlugin} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger, CSSPlugin);

const ProfileInfo = ({deg,name,from,description,mail, image}) => {




    return (
        <StyledProfileInfo className='profile_info'>
            {/*//container*/}
            <Container>
                <Row>
                    <Col md={7}>
                        {/*left column*/}
                        <div className="profile_info__left">
                            {
                                window.innerWidth < 1000 && deg &&
                                <h3 className={'fade-up'}>{deg}</h3>
                            }
                            {
                                name &&
                                <h2 className={'fade-up'}>{name}</h2>

                            }

                            {
                                from &&
                                <h4 className={'fade-up'}>{from} </h4>

                            }
                            {
                                description &&
                                <p className={'fade-up'}>{description}</p>

                            }

                            {
                                mail &&
                                <a  href={`mailto:${mail}`} className={'p1-font fade-up'}>Contact Me</a>

                            }

                        </div>
                    </Col>

                    {
                        image &&
                        <Col md={5}>
                            {/*right column*/}
                            <div className="profile_info__right ">
                                <div className="image-profile profile-photo">
                                    <Img src={image ? image : 'https://placehold.co/400x450'}/>
                                </div>
                            </div>
                        </Col>
                    }

                </Row>
                {/*fixed position name*/}
                {
                    window.innerWidth > 1000 &&  deg &&
                    <div className="fixed-name left-align-text profile-name">
                        <h3>{deg}</h3>
                    </div>
                }
            </Container>

        </StyledProfileInfo>
    );
};

const StyledProfileInfo = styled.section`
  height: 100vh;
  overflow: hidden;

  //fixed position name
  .fixed-name {
    position: absolute;
    bottom: 30px;
    z-index: 1;
    left: 0;
    right: 0;

    h3 {
      font-size: 120px;
      font-weight: 500;
      line-height: 120px;
      letter-spacing: 0.5px;
      @media (min-width: 1800px) {
        font-size: 120px;
        line-height: 120px;
      }
      @media (max-width: 767px) {
        font-size: 42px;
        line-height: 55px;
      }
    }
  }

  //container
  .container {
    height: 100%;
    position: relative;

    .row {
      height: 100%;
    }
  }


  //left column
  .profile_info__left {
    height: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    padding-right: 100px;

    h2 {
      margin-bottom: 5px;
    }

    h3 {
      font-size: 120px;
      font-weight: 500;
      line-height: 120px;
      letter-spacing: 0.5px;
      @media (min-width: 1800px) {
        font-size: 120px;
        line-height: 120px;
      }
      @media (max-width: 767px) {
        font-size: 42px;
        line-height: 55px;
      }
    }
    h4{
      margin-bottom: 30px;
    }
    h1 {
      margin-bottom: 30px;
    }

    .p1-font {
      margin-top: 20px;
      text-decoration: underline;
    }
  }

  //right column
  .profile_info__right {
    height: 100%;
    position: relative;

    .image-profile {
      position: absolute;
      left: -50px;
      top: 50%;
      height: 50vh;
      width: 50vh;
      transform: translateY(-50%);
    }
  }


  //responsive
  @media (max-width: 1000px) {
    height: auto;
    .col-md-7, .col-md-5 {
      max-width: 100%;
      flex: 0 0 100%;
    }

    .profile_info__right {
      .image-profile {
        position: relative;
        left: unset;
        top: unset;
        transform: unset;
        height: auto;
        width: 100%;
        padding-top: 100%;
        margin-top: 40px;
      }
    }

    .profile_info__left {
      padding-right: 15px;
      padding-top: 100px;
      h1,h2,a{
        margin-bottom: 20px;
      }
      
    }
  }
  
  @media(max-width: 767px){
    padding-top: 20px;
    h3{
      margin-bottom: 0;
    }
    h2{
      margin-bottom: 0 !important;
    }
  }
  
  
`;

export default ProfileInfo;
