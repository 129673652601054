import React from 'react';
import styled from 'styled-components';
import {hover, primary, secondary} from "../styles/globalStyleVars";
import {Link} from "react-router-dom";
import {Img} from "./Img";


const PortfolioItem = ({title, description, image, link}) => {


    return (<StyledPortfolioItem className={`portfolioItem fade-up`}>
        <a href={link ? link : "https://abc.com"} target={'_blank'}>
            <div className="portfolio-item-single">
                <div className="portfolio-item-single__image">
                    <Img src={image ? image : 'https://placehold.co/400x450'}/>
                </div>
                <div className="portfolio-item-single__name">
                    <h4>{title ? title : 'Website Name'}</h4>
                    <p>{description ? description : ''}</p>
                </div>
            </div>
        </a>
        </StyledPortfolioItem>)
};

const StyledPortfolioItem = styled.div`
  margin-bottom: 30px;
  .portfolio-item-single{
    &__image{
      position: relative;
      overflow: hidden;
      padding-top: calc(250 / 400 * 100%);
      img{
        will-change: transform;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        -webkit-transition: -webkit-transform 3s;
        transition: -webkit-transform 3s;
        object-position: top !important;
        transition: 3s all ease !important;
        border-radius: 20px;
      }
    }
    &__name{
      margin-top: 10px;
      h4{
        font-size:17px;
        margin: 0;
        transition: 0.7s all ease !important;

      }
      p{
        transition: 0.7s all ease !important;
        font-size: 14px;
        line-height:20px;
      }
    }
    
    &:hover{
      .portfolio-item-single__image{
        img{
          -webkit-transform: scale(1) !important;
          -ms-transform: scale(1) !important;
          transform: scale(1) !important;
          object-position: bottom !important;
        }
        
      }
      
      h4,p{
        color: ${hover}
      }
    }
  }
`;


export default PortfolioItem;
