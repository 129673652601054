import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";

import {
    BrowserRouter as Router,
    Switch,
    Route,
    useParams,
    useHistory, useLocation
} from "react-router-dom";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import {Swiper, SwiperSlide} from 'swiper/react';
import swiper, {Autoplay, Navigation, Pagination, EffectFade} from "swiper";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import gsap, {CSSPlugin, TimelineLite} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import SocialShare from "../../components/SocialShare";
import {black, secondary, white} from "../../styles/globalStyleVars";
import {Img} from "../../components/Img";
import { CommentSection } from 'react-comments-section'
import 'react-comments-section/dist/index.css'
import SocialShareIcons from "../../components/SocialShareIcons";
gsap.registerPlugin(CSSPlugin,ScrollTrigger);


const MyComponent = () => {




    //variabls
    const location = useLocation();



        useEffect(() => {
            document.querySelector('body').classList.add('details-page');
        },[location?.pathname])


    const customNoComment = () => (
        <div className='no-com'>Sheessh! Zero Comments posted here!</div>
    )



    const [data, setData] = useState([
        {
            userId: '01a',
            comId: '012',
            fullName: 'Riya Negi',
            avatarUrl: 'https://ui-avatars.com/api/name=Riya&background=random',
            userProfile: 'https://www.linkedin.com/in/riya-negi-8879631a9/',
            text: 'Hey, Loved your blog! ',
            replies: [
                {
                    userId: '02b',
                    comId: '017',
                    fullName: 'Lily',
                    userProfile: 'https://www.linkedin.com/in/riya-negi-8879631a9/',
                    text: 'I have a doubt about the 4th point🤔',
                    avatarUrl: 'https://ui-avatars.com/api/name=Lily&background=random',
                    replies: []
                }]
        } ])
    return (

        <HelmetProvider>
            {/*dynamic title */}
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{`Blog Details - Single Portfolio React Template`}</title>
                <meta name="description"
                      content={"Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aspernatur, voluptate!"}/>
                <meta name="og:title" content={"Steve - Single Portfolio React Template"}/>
                <meta name="og:description"
                      content={"Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aspernatur, voluptate!"}/>

            </Helmet>

            <StyledComponent>
                <section   className="details-wrapper">
                    <Container>
                        <Row  className={'main-row'}>

                            <Col md={8} className={'content-wrapper'}>
                                <ul className="intro">
                                    <li>
                                        <p className="gray">{'Blog'}</p>
                                    </li>
                                    <li>
                                        <p className="gray">{'09 April'}</p>
                                    </li>
                                </ul>
                                <h1>
                                    Designing with Light: 6 Ways Architects Are Using Swisspearl’s Progressive Cladding Panels
                                </h1>
                                <div>
                                    {/*{ReactHtmlParser(details?.post_content)}*/}
                                </div>
                                <div className="banner img-group-caption">
                                    <div className="img-wrapper-big">
                                        <Img src={"/images/dynamic/blog-1.jpg"}/>
                                    </div>
                                </div>
                                <p>[Excerpt] In today's fast-paced and interconnected world, embracing diversity has become more than just a buzzword—it has become a strategic imperative for success. </p>

                                <h3>[Section Title] Introduction</h3>
                                <p>Nowhere is this more evident than in our group of companies, where we celebrate the power of diversity and leverage the unique strengths of each industry to drive collective growth and innovation. In this blog, we will explore how the diverse portfolio of our group unlocks endless possibilities, fosters collaboration, and propels us forward as a force to be reckoned with.</p>

                                <ul className={'small-list'}>
                                    <li>Defining your financial objectives and timeline.</li>
                                    <li>Creating a comprehensive plan to achieve your goals</li>
                                    <li>Setting realistic expectations and benchmarks for success.</li>
                                </ul>
                                <div className="img-group-caption">
                                    <div className="img-wrapper-big">
                                        <Img src={"/images/dynamic/blog-2.jpg"}/>
                                    </div>
                                    <p>[Excerpt] In today's fast-paced and interconnected world, embracing diversity has become more than just a buzzword—it has become a strategic imperative for success. </p>
                                </div>
                                <table>

                                    <tbody>
                                    <tr>
                                        <th>[Table Title] Indicator</th>
                                        <th>[Table Title] Description</th>
                                    </tr>
                                    <tr>
                                        <td>Stock Market Indices</td>
                                        <td>Performance of major stock market indices</td>
                                    </tr>
                                    <tr>
                                        <td>Stock Market Indices</td>
                                        <td>Performance of major stock market indices</td>
                                    </tr>
                                    <tr>
                                        <td>Stock Market Indices</td>
                                        <td>Performance of major stock market indices</td>
                                    </tr>

                                    </tbody>
                                </table>

                                <blockquote className={'split-up'}>
                                    [Block Quote] The journey to financial independence begins with a clear
                                    destination
                                    in mind. Setting meaningful financial goals provides the foundation for building
                                    wealth and creating a roadmap to success. By crafting a comprehensive plan
                                    tailored
                                    to your aspirations, you can turn your dreams into actionable steps.
                                    <span>- John Smith</span>
                                </blockquote>

                                <h3>[Section Subtitle] Fueling Innovation through Cross-Pollination</h3>
                                <p>Within our group of companies, we have the distinct advantage of bringing together professionals from a wide range of industries, each with their own set of expertise and perspectives. This cross-pollination of ideas sparks innovation, as individuals from different backgrounds collaborate, challenge assumptions, and contribute fresh insights. By embracing diversity, we create a fertile ground for creativity and unlock the potential for groundbreaking solutions that transcend traditional boundaries.</p>

                                <ul className={'big-list'}>
                                    <li>
                                        True Professionals
                                        <p>{'Lorem Ipsum is simply dummy text of the printing and typesetting industry.'}</p>
                                    </li>
                                    <li>
                                        Concise Strategies
                                        <p>{'Lorem Ipsum is simply dummy text of the printing and typesetting industry.'}</p>
                                    </li>
                                    <li>
                                        Winning Mentality
                                        <p>{'Lorem Ipsum is simply dummy text of the printing and typesetting industry.'}</p>
                                    </li>
                                    <li>
                                        Accessible Success
                                        <p>{'Lorem Ipsum is simply dummy text of the printing and typesetting industry.'}</p>
                                    </li>
                                    <li>
                                        Embrace yourself
                                        <p>{'Lorem Ipsum is simply dummy text of the printing and typesetting industry.'}</p>
                                    </li>
                                </ul>
                                <div className="img-group-multiple">
                                    <Row>
                                        <Col md={12}>
                                            <div className="img-wrapper-big">
                                                <Img src={"/images/dynamic/blog-4.avif"}/>
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div className="img-wrapper-small">
                                                <Img src={"/images/dynamic/blog-6.avif"}/>
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div className="img-wrapper-small">
                                                <Img src={"/images/dynamic/blog-7.avif"}/>
                                            </div>
                                        </Col>
                                    </Row>

                                </div>
                                <h3>[Section Subtitle] Conclusion</h3>
                                <p>We recognize that diversity is not just a checkbox—it is a strategic advantage that drives innovation, amplifies synergies, enhances adaptability, and cultivates a culture of continuous learning. By harnessing the strengths across industries, we unlock endless possibilities and position ourselves at the forefront of transformative change. Together, we are a dynamic force, united by our shared commitment to embracing diversity and driving collective success.</p>
                                <div    className={'social-pin-wrapper'}>
                                    <div  className="social-wrapper-main">
                                        <p className="gray">Share:</p>
                                        <SocialShareIcons/>
                                    </div>
                                </div>
                                <div className="comments-wrapper">
                                    <CommentSection
                                        currentUser={{
                                            currentUserId: '01a',
                                            currentUserImg:
                                                'https://ui-avatars.com/api/name=Riya&background=random',
                                            currentUserProfile:
                                                'https://www.linkedin.com/in/riya-negi-8879631a9/',
                                            currentUserFullName: 'Riya Negi'
                                        }}
                                        logIn={{
                                            loginLink: 'http://localhost:3001/',
                                            signupLink: 'http://localhost:3001/'
                                        }}
                                        commentData={data}
                                        onSubmitAction={() =>
                                            setData([
                                                {
                                                    userId: '02a',
                                                    comId: '015',
                                                    fullName: 'Robert Jae',
                                                    avatarUrl:
                                                        'https://ui-avatars.com/api/name=Robert&background=random',
                                                    text: 'Woah pretty helpful! how did you solve for x?',
                                                    userProfile: 'https://www.linkedin.com/in/riya-negi-8879631a9/',
                                                    replies: [
                                                        {
                                                            userId: '01b',
                                                            comId: '016',
                                                            userProfile:
                                                                'https://www.linkedin.com/in/riya-negi-8879631a9/',
                                                            fullName: 'Adam Scott',
                                                            text: 'Thanks! refer to this link -> acs.com',
                                                            avatarUrl:
                                                                'https://ui-avatars.com/api/name=Adam&background=random'
                                                        }
                                                    ]
                                                }
                                            ])
                                        }
                                        onDeleteAction={() => window.prompt('Are you sure?')}
                                        onReplyAction={() => alert('Reply was posted')}
                                        onEditAction={() => alert('Reply was edited!')}
                                    />
                                </div>
                            </Col>




                        </Row>
                    </Container>
                </section>

            </StyledComponent>
        </HelmetProvider>

    );
};

const StyledComponent = styled.section`
  overflow: hidden;
  .details-wrapper {
    padding-top: 150px;
    overflow: hidden;
    .intro{
      margin-bottom: 30px;
    }
    
    .gray {
      color: ${white};
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      margin: 0 !important;
      text-align: right;
      p{
        margin: 0 !important;  
      }
    }

    .social-pin-wrapper {
      margin-bottom: 40px !important;

      .gray{
        margin-bottom: 20px !important;
      }
      .social {
        flex-wrap: wrap;
        align-items: flex-end;
        justify-content: flex-end;
        gap: 20px;

        li {
          margin-left: 0;
        }
      }
    }

    .content-wrapper {
      //padding-bottom: 60px;
      border-bottom: 1px solid ${white};
      .intro {
        display: flex;
        justify-content: space-between;
      }
      .video_body{
        margin-bottom: 60px;
        padding: 0 !important;
        margin-right: -15px;
        margin-left: -15px;
        background: transparent !important;
        &:after{
          display: none !important;
        }
      }
      h1 {
        margin-bottom: 60px;
        font-size: 40px;
        font-weight: 500;
        line-height: 40px;
        letter-spacing: 0.5px;
        color: ${white};
      }

      p {
        margin-bottom: 60px;
        color: ${white};
      }

      h3 {
        color: ${white};
        margin-bottom: 30px;
      }

      .small-list {
        margin-top: 0;
      }

      
      .big-list{
        margin-bottom: 60px;
        p{
          margin-bottom: 0 !important;
        }
      }
      .img-group-caption {
        margin-bottom: 60px;
        .img-wrapper-big {
          position: relative;
          padding-top: calc(520 / 870 * 100%);
        }

        p {
          margin-top: 20px;
        }
        
        
        &.banner{
          .img-wrapper-big{
            padding-top: calc(400 / 870 * 100%);

          }

        }
      }
      .img-group-multiple {
        margin-bottom: 40px;

        .img-wrapper-big {
          position: relative;
          padding-top: calc(520 / 870 * 100%);
          margin-bottom: 30px;
        }
        .img-wrapper-small {
          position: relative;
          padding-top: calc(250 / 420 * 100%);
        }

      }

      table {
        margin-bottom: 40px;
      }

      .image-gallery-slider {
        position: relative;
        margin-bottom: 20px;

        .navigation {
          position: absolute;
          bottom: 55px;
          right: 0;
        }

        .swiper-slide-active {
          p {
            transition-delay: 0.7s;
            opacity: 1;
          }
        }

        p {
          opacity: 0;
          transition: 0.7s all cubic-bezier(.54,.32,.05,.96);
          max-width: 70%;
          margin-top: 50px;
        }
      }

      blockquote {
        margin-bottom: 60px;
      }
    }

    

    
    
  }

  
  //video style remove for grid 
  .video_body{
    .container{
      max-width: 100%;
      width: 100%;
      padding: 0;
      margin: 0;
      .row{
        margin: 0;
      }
    }
  }
  @media(max-width: 767px){
    .details-wrapper{
      padding-bottom: 120px;
      margin: 0 15px;
      .main-row{
        flex-direction: column-reverse;
      }
      .content-wrapper{
        padding: 0;
         .image-gallery-slider .navigation{
           right: unset;
           left: unset;
           bottom: unset;
           position: relative;
         }
      }
      .social-pin-wrapper {
        margin-top: 60px;
        padding: 0;
        .social {

          flex-direction: row;
          gap: 20px;

          li {
            margin-left: 0;
          }
        }
      }
      
      
      .img-group-multiple{
        .col-md-6{
          &:last-child{
            margin-top: 30px;
          }
        }
      }
    }
  }

`;

export default MyComponent;
