import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Col, Container, Form, Row} from "react-bootstrap";
import {emailValidation, emptyNumber, emptyValidation} from "./../../../api/config/validator";
import {toast} from 'react-toastify';
import Button from "../../../components/Button";
import {primary, white} from "../../../styles/globalStyleVars";
import {SocialIcon} from 'react-social-icons'
import {useForm} from "react-hook-form";
import axios from "axios";
import 'react-toastify/dist/ReactToastify.css';
const Contacts = ({data, callContactForm, loading, formData, formDataFailed, clearContact}) => {

    // api data
    const [isSubmitting, setIsSubmitting] = useState(true);
    useEffect(() => {
        setTimeout(() => {
            setIsSubmitting(false);
        }, 300);
    }, []);
    const { register, handleSubmit, formState, reset } = useForm({ mode: "all" });
    const { errors, isSubmitSuccessful } = formState;

    const success = (msg) => toast.success(msg, {
        position: "top-right",
        autoClose: 4000,
        closeOnClick: true,
        progress: undefined,

    });

    const error = (msg) => toast.error(msg, {
        position: "top-right",
        autoClose: 4000,
        closeOnClick: true,
        progress: undefined,

    });





    const onSubmit = async (data) => {
        setIsSubmitting(true); // Show spinner
        try {
            const formData = new FormData();
            formData.append("_wpcf7_unit_tag", "form_" + "7");
            formData.append("your-name", data?.name);
            formData.append("email", data?.email);
            formData.append("phone", data?.phone);
            formData.append("message", data?.message);

            const response = await axios.post(
                "https://link.devsopu.com//wp-json/contact-form-7/v1/contact-forms/7/feedback",
                formData
            );


            if (response.status === 200) {
                success(response.data?.message);
                console.log(response.data, 'submit')
                reset(); // Reset form fields
            } else {
                error("Failed to submit form. Please try again later.");
            }
        } catch (err) {
            error(
                err?.message
                    ? err?.message
                    : "Failed to submit form. Please try again later."
            );
        } finally {
            setIsSubmitting(false); // Hide spinner
        }
    };

    const onError = (errors) => {
        // setToastShown(false); // Reset toast shown state on new submission attempt
        const firstError = Object.values(errors)[0];
        error(firstError.message);
    };

    return (
        <StyledContacts className='address pt-160 pb-160'>
            <Container className={'custom-container'}>
                <Row className='address__row'>
                    <Col className={`address__left fade-up`} sm={4}>
                        <h2>GET IN TOUCH</h2>

                        <Form  onSubmit={handleSubmit(onSubmit, onError)}>
                            <div className="form-group">
                                <Form.Group controlId="formBasicEmail">
                                    <Form.Control
                                        className={
                                            formState?.errors?.name?.message
                                                ? "has-error form-control-lg"
                                                : "form-control-lg"
                                        }
                                        {...register("name", {
                                            required: "Please enter your full name",
                                            pattern: {
                                                value: /^[A-Za-z ]+$/,
                                                message: "Name must contain only letters",
                                            },
                                        })}
                                        type="text"
                                        placeholder="Name*"
                                    />
                                    {errors.name && (
                                        <span className="error-message form-error">
                      {errors.name.message}
                    </span>
                                    )}
                                </Form.Group>
                            </div>
                            <div className="phoneEmail">
                                <div className="form-group">
                                    <Form.Group controlId="formBasicEmail">
                                        <Form.Control
                                            className={
                                                formState?.errors?.email?.message
                                                    ? "has-error form-control-lg"
                                                    : "form-control-lg"
                                            }
                                            {...register("email", {
                                                required: {
                                                    value: true,
                                                    message: "Please enter a valid email address",
                                                },
                                                pattern: {
                                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                    message: "Please enter a valid email address",
                                                },
                                            })}
                                            type="email"
                                            placeholder="Email*"
                                        />
                                        {errors.email && (
                                            <span className="error-message form-error">
                        {errors.email.message}
                      </span>
                                        )}
                                    </Form.Group>
                                </div>
                                <div className="form-group">
                                    <Form.Group controlId="formBasicPhone">
                                        <Form.Control
                                            className={
                                                formState?.errors?.phone?.message
                                                    ? "has-error form-control-lg"
                                                    : "form-control-lg"
                                            }
                                            {...register("phone", {
                                                required: {
                                                    value: true,
                                                    message: "Please enter your phone number",
                                                },
                                                pattern: {
                                                    value: /^01[0-9]{9}$/,
                                                    message: "Please enter a valid 11 digit phone number",
                                                },
                                            })}
                                            type="number"
                                            placeholder="Phone Number*"
                                        />
                                        {errors.phone && (
                                            <span className="error-message form-error">
                        {errors.phone.message}
                      </span>
                                        )}
                                    </Form.Group>
                                </div>
                            </div>
                            <div className="form-group">
                                <Form.Group controlId="formBasicPhone">
                                    <Form.Control
                                        as="textarea"
                                        rows={4}
                                        className={
                                            formState?.errors?.message?.message
                                                ? "has-error form-control-lg"
                                                : "form-control-lg"
                                        }
                                        {...register("message", {
                                            required: {
                                                value: true,
                                                message: "Please enter your Message",
                                            },
                                        })}
                                        // type="textarea"
                                        placeholder="Message*"
                                    />
                                    {errors.message && (
                                        <span className={"form-error error-message"}>
                      {errors.message?.message}
                    </span>
                                    )}
                                </Form.Group>
                            </div>
                            <div className={"form-group width-fit"}>
                                <div onClick={handleSubmit(onSubmit, onError)}>
                                    <Button src={"javascript:void(0)"} text={"Submit Message"} />
                                </div>
                            </div>
                        </Form>

                    </Col>
                    <Col sm={4} className='fade-up address__right'>
                        {
                            data?.posts?.address &&
                            <div className="single-item">
                                <h4 className={''}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                         fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                         stroke-linejoin="round">
                                        <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path>
                                        <circle cx="12" cy="10" r="3"></circle>
                                    </svg>
                                    <span>Address</span></h4>
                                <p>{data?.posts?.address}</p>
                            </div>
                        }

                        {
                            data?.posts?.phone &&
                            <div className="single-item">
                                <h4 className={''}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                         fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                         stroke-linejoin="round">
                                        <path
                                            d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path>
                                    </svg>
                                    <span>Phone</span></h4>
                                <p>{data?.posts?.phone}</p>
                            </div>
                        }

                        {
                            data?.posts?.email &&
                            <div className="single-item">
                                <h4>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                         fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                         stroke-linejoin="round">
                                        <path
                                            d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
                                        <polyline points="22,6 12,13 2,6"></polyline>
                                    </svg>
                                    <span>Email</span></h4>
                                <p><a href={`mailto:${data?.posts?.email}`}>{data?.posts?.email}</a></p>
                            </div>
                        }


                        <dic className="social fade-up">
                            <h3>FIND ME</h3>
                            <ul>
                                <li><SocialIcon bgColor={primary} fgColor={white} target={'_blank'} href={data?.posts?.github} url={data?.posts?.github}/></li>
                                <li><SocialIcon bgColor={primary} fgColor={white} target={'_blank'} href={data?.posts?.facebook} url={data?.posts?.facebook}/></li>
                                <li><SocialIcon bgColor={primary} fgColor={white} target={'_blank'} href={data?.posts?.linkedin} url={data?.posts?.linkedin}/></li>
                                <li><SocialIcon bgColor={primary} fgColor={white} target={'_blank'} href={data?.posts?.stackoverflow} url={data?.posts?.stackoverflow}/></li>
                                <li><SocialIcon bgColor={primary} fgColor={white} target={'_blank'} href={`mailto:${data?.posts?.email}`} fallback="email"/></li>
                            </ul>
                        </dic>
                    </Col>
                </Row>
            </Container>
        </StyledContacts>
    );
};
const StyledContacts = styled.section`

    h2 {
        margin-bottom: 40px;
    }

    .single-item {
        margin-bottom: 30px;
        boder-bottom: 1px solid #e6e6e6;

        h4 {
            align-items: center;
            display: inline-flex;
            margin-bottom: 10px;

            svg {
                margin-right: 15px;
                width: 20px;
                height: 20px;
            }
        }

        p {
            padding-left: 35px;
        }
    }

    .social {
        ul {
            display: flex;
            gap: 15px;
            flex-direction: row;
        }
    }
`;


export default Contacts;