import React, {useEffect} from 'react';
import {useSelector, useDispatch} from "react-redux";
import {fetchPostDetail, fetchPosts} from "../../api/redux/post";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import styled from "styled-components";
import ProfileInfo from "./components/ProfileInfo";
import Skills from "./components/Skills";
import Experience from "./components/Experience";
import Portfolio from "./components/Portfolio";
import Blogs from "./components/Blogs";
import Contacts from "./components/Contacts";
import Services from "./components/Services";
import gsap, {CSSPlugin} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import {useLocation} from "react-router-dom";
import {Helmet, HelmetProvider} from "react-helmet-async";
import ReactHtmlParser from "react-html-parser";

gsap.registerPlugin(ScrollTrigger, CSSPlugin);

const Home = () => {


    // this code for api call
    const dispath = useDispatch()

    // call your specific home page api
    useEffect(() => {
        let api_url = apiEndPoints.SITESETTING
        let api_url_home = apiEndPoints.HOME
        dispath(fetchPosts([api_url]))
        dispath(fetchPostDetail([api_url_home]))
    }, [])


    // store the data in this variable
    let getPost = useSelector(state => state.posts)


    // variables
    const location = useLocation();


    // parallax animation
    useEffect(() => {
        const left_text_animation = document.querySelectorAll('.profile-name');
        const right_text_animation = document.querySelectorAll('.left-to-right');
        const allAnim = document.querySelectorAll('.fade-up');

        if (window.innerWidth > 1000) {

            left_text_animation.forEach((el, index) => {

                gsap.to(el, {
                    x: '150%',
                    ease: "none",
                    duration: 30,
                    stagger: 10,
                    scrollTrigger: {
                        trigger: el,
                        start: "center 70%",
                        end: "center 30%",
                        scrub: true,
                        id: "2",
                        duration: 30,
                        stagger: 10,


                    }
                });
            });
            right_text_animation.forEach((el, index) => {

                gsap.to(el, {
                    x: '0',
                    ease: "none",
                    duration: 30,
                    stagger: 10,

                    scrollTrigger: {
                        trigger: el,
                        start: "center 70%",
                        end: "center 30%",
                        scrub: true,
                        id: "3",
                        duration: 30,
                        stagger: 10,

                    }
                });
            });


            allAnim.forEach((el, index) => {
                gsap.fromTo(el, {
                    autoAlpha: 0,
                    y: 50,
                    ease: "none",
                }, {
                    y: 0,
                    autoAlpha: 1,
                    ease: "power2",
                    duration: 1,
                    scrollTrigger: {
                        id: `${index + 1}`,
                        trigger: el,
                        // start: 'top center+=100',
                        toggleActions: 'play none none reverse',
                    }
                })
            })


        }
    }, [location.pathname])

    return (


        <HelmetProvider>
            {/*dynamic title */}
            <Helmet>
                <meta charSet="utf-8"/>
                <link href={getPost?.posts?.favicon_url} rel="icon"/>
                <title>{`${getPost?.posts?.site_name} - ${getPost?.posts?.description}`}</title>
                <meta name="description"
                      content={`${getPost?.posts?.site_name} - ${getPost?.posts?.description}`}/>
                <meta name="og:title" content={`${getPost?.posts?.site_name} - ${getPost?.posts?.description}`}/>
                <meta name="og:description"
                      content={`${getPost?.posts?.site_name} - ${getPost?.posts?.description}`}/>

            </Helmet>

            <StyledSection>
                <div id={'info'}>
                    <ProfileInfo mail={getPost?.detail?.acf_fields?.email} image={getPost?.detail?.acf_fields?.person_image}
                                 deg={getPost?.posts?.description} name={getPost?.posts?.site_name} from={getPost?.detail?.acf_fields?.country}
                                 description={ReactHtmlParser(getPost?.detail?.acf_fields?.description)}/>
                </div>
                <div id={'portoflio'}>
                    <Portfolio data={getPost?.detail?.portfolio} title={'PORTFOLIO'}/>
                </div>
                <div id={'skills'}>
                    <Skills data={getPost?.detail?.acf_fields?.skills} title={'SKILLS'}/>
                </div>
                <div id={'experience'}>
                    <Experience data={getPost?.detail?.acf_fields?.experience} title={'EXPERIENCE'}/>
                </div>

                <div id={'blogs'}>
                    <Blogs data={getPost?.detail?.blogs} title={'BLOGS'}/>
                </div>
                <Services data={getPost?.detail?.services} title={'SERVICES'}/>
                {/*<TestimonialCarousel title={'REVIEWS'}/>*/}
                <div id={'contacts'}>
                    <Contacts data={getPost}/>
                </div>
            </StyledSection>
        </HelmetProvider>

    );
};

const StyledSection = styled.section`

`;

export default Home;
