import React from 'react';
import styled from 'styled-components';
import {primary, white} from "../styles/globalStyleVars";
import {Link} from "react-router-dom";
import {Img} from "./Img";


const BlogItem = ({title, category, date, image, content, link}) => {
// Create a Date object from the input date string
    const dateFormat = new Date(date);

// Format the date as '12 July 2034'
    const options = { day: 'numeric', month: 'long', year: 'numeric' };
    const formattedDate = dateFormat.toLocaleDateString('en-GB', options);

    return (<StyledBlogItem className={`BlogItem fade-up`}>
        <Link to={link} target={'_blank'}>
            <a target={'_blank'}>
                <div className="portfolio-item-single">
                    <div className="portfolio-item-single__image">
                        <div className="portfolio-item-single__cat">
                            <ul>
                                {
                                    category &&
                                    category.map((e) => {
                                        return (
                                            <li>{e}</li>

                                        )
                                    })
                                }
                            </ul>
                            <p>{formattedDate ? formattedDate : '10 July 2023'}</p>
                        </div>
                        {/*<Img src={image ? image : 'https://placehold.co/400x250'}/>*/}
                    </div>
                    <div className="portfolio-item-single__name">

                        <h4>{title ? title : 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eveniet, pariatur.'}</h4>

                    </div>
                </div>
            </a>
        </Link>
    </StyledBlogItem>)
};

const StyledBlogItem = styled.div`
    margin-bottom: 30px;

    .portfolio-item-single {
        &__image {
            border-radius: 20px;
            position: relative;
            overflow: hidden;
            //padding-top: calc(250 / 400 * 100%);

            &:after {
                content: '';
                position: absolute;
                inset: 0;
                height: 100%;
                width: 100%;
                background: rgba(0, 0, 0, 0.5)
            }

            img {
                transform: scale(1) !important;
                transition: 0.7s all ease !important;
            }
        }

        &__cat {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 100%;
            padding: 20px;
            margin: 0 auto;
            z-index: 11;
            bottom: 0;
            display: flex;
            justify-content: space-between;
            flex-direction: column;

            ul {
                display: flex;
                gap: 10px;

                li {
                    border-radius: 15px;
                    padding: 3px 10px;
                    background: ${primary};
                    color: ${white} !important;
                    transition: 8s all ease !important;


                }
            }

            p {
                transition: 0.7s all ease !important;
            }
        }

        &__name {
            margin-top: 10px;

            h4 {
                font-size: 17px;
                margin: 0;
                transition: 0.7s all ease !important;


            }

            p {
                font-size: 14px;
                line-height: 20px;
            }
        }

        &:hover {
            .portfolio-item-single__image {
                img {
                    transform: scale(1.04) !important;

                }
            }

            p {
                color: ${primary};
            }

            h4 {
                color: ${primary};
            }
        }
    }
`;


export default BlogItem;
