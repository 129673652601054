import React, { useContext } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { TransitionProvider } from '../helper/TransitionContext';
import TransitionComponent from '../helper/Transition';
import Home from '../pages/home';
import BlogSingle from '../pages/blogs/single';
import Error from '../pages/404';

const Router = () => {
  return (


      // declare the route
      <TransitionProvider>
        <Routes>
          <Route exact
                 index
                 element={
                   <TransitionComponent>
                     <Home />
                   </TransitionComponent>
                 }
          />
            <Route exact path={'blogs/:slug'}
                   index
                   element={
                       <TransitionComponent>
                           <BlogSingle />
                       </TransitionComponent>
                   }
            />


          {/* Other router */}
          <Route path="*" element={<Error />} />
        </Routes>
      </TransitionProvider>

  );
};

export default Router;
