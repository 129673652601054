import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import {Container, Row, Col} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import {primary, white, hover} from "../../styles/globalStyleVars";


const MyComponent = ({data}) => {


    // variables
     const [scrollUp, setScrollUp] = useState(false);
    const [scrollDown, setScrollDown] = useState(false);



    // menu fixed on scroll
    useEffect(() => {
        if (document.body?.classList.contains('scroll-down')) {
            document.body?.classList.remove('scroll-down');
        }
    });


    // on scroll to show sticky menu function
    useEffect(() => {
        const body = document.body;
        const scrollUp = 'scroll-up';
        const scrollDown = 'scroll-down';
        let lastScroll = 0;
        let howMuchScroll = 150;
        if (window.screen.width < 991) {
            howMuchScroll = 0;
        } else {
            howMuchScroll = 150;
        }

        window.addEventListener('scroll', () => {
            let currentScroll = window.pageYOffset;

            if (currentScroll <= howMuchScroll) {
                body?.classList.remove(scrollUp);
                return;
            }
            if (currentScroll > lastScroll && !body.classList.contains(scrollDown)) {
                // down
                body?.classList.remove(scrollUp);
                body?.classList.add(scrollDown);
            } else if (currentScroll < lastScroll && body.classList.contains(scrollDown)) {
                // up
                body?.classList.remove(scrollDown);
                body?.classList.add(scrollUp);
            }
            lastScroll = currentScroll;
        });
    }, []);

    const [activeMenuItem, setActiveMenuItem] = useState('');

    const handleMenuClick = (menuId) => {
        // Set the clicked menu item as active
        setActiveMenuItem(menuId);
    };

    const menuItems = [
        { id: 'info', label: 'ABOUT' },
        { id: 'skills', label: 'SKILLS' },
        { id: 'experience', label: 'EXPERIENCE' },
        { id: 'blogs', label: 'BLOGS' },
        { id: 'contacts', label: 'CONTACTS' },
    ];



    return (
        <>
       
            {/*main or default menu */}
            <StyledDefaultMenu
                className={`${scrollDown ? 'scroll-down' : ''} ${scrollUp ? 'scroll-up' : ''}  main-menu-desktop black`}>
                <Container className={'desktop-default'}>
                    <Row>
                        <Col>
                            <div className={'header'}>

                                {/*logo*/}
                                <div className="logo">
                                    <Link to={'/'}>
                                        <h3>{data?.posts?.site_name}</h3>
                                    </Link>
                                </div>

                                {/*menu list */}
                                <div>
                                    <ul className={'header__menu'}>
                                        {menuItems.map((menuItem) => (
                                            <li
                                                key={menuItem.id}
                                                className={menuItem.id === activeMenuItem ? 'active' : ''}
                                            >
                                                <a href={`#${menuItem.id}`} onClick={() => handleMenuClick(menuItem.id)}>
                                                    {menuItem.label}
                                                </a>
                                            </li>
                                        ))}
                                    </ul>
                                </div>

                          
                            </div>
                        </Col>
                    </Row>
                </Container>
            </StyledDefaultMenu>
        </>

    );
}


// style for DefaultMenu
const StyledDefaultMenu = styled.section`
  z-index: 50;
  width: 100%;
  top: 0;
  left: 0;
  position: fixed;
  overflow: hidden;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background-color: rgba(7,7,7,.5);



  //header 
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__menu {
      display: flex;
      grid-template-columns: repeat(4, 1fr);

      li:first-child {
        padding-left: 58px;
      }

      li:not(:last-child) {
        margin-right: 30px;
      }

      li {
        display: flex;
        justify-content: center;

        a {
          color: ${white};
          font-size: 16px;
          font-weight: 400;
          line-height: 20px;

          &:hover {
            color: ${hover} !important;
          }

          
        }
        
        &.active {
         a{
           color: ${hover};
           opacity: 1;
         }
        }
      }
    }

    &__right {
      margin-left: auto;

      li {
        a {
          color: ${white};
          font-size: 16px;
          font-weight: 400;
          line-height: 20px;
          &:hover{
            color: ${primary};
          }
        }
      }

      &__logo {
        cursor: pointer;
        display: flex;
        margin-left: auto;

        button {
          display: flex;
          background-color: unset;
          border: unset;
        }

        p {
          color: ${white};
          font-size: 16px;
          font-weight: 400;
          line-height: 20px;
        }
      }
    }

    .active {
      a {
        opacity: 70%;;
      }
    }
  }

  @media (max-width: 991px) {
    display: none;
  }

`;


export default React.memo(MyComponent);
