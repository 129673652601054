import GlobalStyle from "./styles/globalStyle";
import Router from "./router/Router";
import {useLocation} from "react-router-dom";
import Background from "./helper/Background";
import {useEffect,useState} from "react";
import Footer from "./layouts/footer/Footer";
import MegaMenu from "./layouts/menu/DesktopMenu";
import MobileMegaMenu from "./layouts/menu/MobileMenu";
import {CSSPlugin, gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import ProgressBar from "react-scroll-progress-bar";
import {secondary} from "./styles/globalStyleVars";
import {Loading} from "./components/Loading";
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "./api/network/apiEndPoints";
import {fetchPostDetail, fetchPosts} from "./api/redux/post";
import {ToastContainer} from "react-toastify";

gsap.registerPlugin(ScrollTrigger, CSSPlugin);


function App() {

    // Get the current location using the useLocation hook from react-router-dom
    const location = useLocation();
    const path = location.pathname;
    const [loading, setLoading] = useState(true);



    // Automatically scrolls to top whenever pathname changes
    useEffect(() => {
        window.scrollTo(0, 0);
        document.body.style.overflow = 'auto';

        if (path !== '/') {
            // Remove the "active" class from all links
            const links = document.querySelectorAll('.navbar-nav a[href^="#"]');
            links.forEach((link) => {
                link.classList.remove('active');
            });
        }
    }, [path]);


    // render loading indicator
    useEffect(() => {
        // Simulating a delay for the content to load (you can replace this with your actual data fetching logic)
        const delay = setTimeout(() => {
            setLoading(false);
        }, 2000);

        // Clean up the timeout in case the component unmounts before the delay completes
        return () => clearTimeout(delay);
    }, [path]);

    // this code for api call
    const dispath = useDispatch()

    // call your specific home page api
    useEffect(() => {
        let api_url = apiEndPoints.SITESETTING
        let api_url_home = apiEndPoints.HOME
        dispath(fetchPosts([api_url]))
        dispath(fetchPostDetail([api_url_home]))
    }, [])


    // store the data in this variable
    let getPost = useSelector(state => state.posts)



    return (
        <>
            <GlobalStyle/>
            <ToastContainer />
            <div className="progress-bar">
                <ProgressBar height="3px" bgcolor={secondary} duration="0.2"/>
            </div>

            <div className="App">
                {loading ? (
                    // Display the loading screen or spinner
                    <Loading/>
                ) : <>
                    <MegaMenu data={getPost}/>
                    <MobileMegaMenu data={getPost}/>

                    {/*open to work and resume fixed button */}
                    <div className="open-to-work-button">
                        <a href={getPost?.detail?.acf_fields?.resume} target={'blank'}><span>DOWNLOAD CV</span></a>
                        <a href={`mailto:${getPost?.posts?.email}`}><span>OPEN TO WORK</span></a>
                    </div>
                    <Router/>
                    <Footer/>
                    <Background/>
                </>
                }


            </div>
        </>


    );
}

export default App;
