import React from 'react';
import styled from "styled-components";

const CloseIconMobile = () => {
    return (
        <StyledCloseIconMobile>
            <svg xmlns="http://www.w3.org/2000/svg" width="24.042" height="24.042"
                 viewBox="0 0 24.042 24.042">
                <g id="Lines" transform="translate(-332.979 -31.979)">
                    <line id="Line_3965" data-name="Line 3965" x1="30"
                          transform="translate(355.606 33.394) rotate(135)" fill="none"
                          stroke="#fff" stroke-linecap="round" stroke-width="2"/>
                    <line id="Line_3966" data-name="Line 3966" x1="30"
                          transform="translate(334.393 33.393) rotate(45)" fill="none"
                          stroke="#fff" stroke-linecap="round" stroke-width="2"/>
                    <line id="Line_3967" data-name="Line 3967" x1="30"
                          transform="translate(355.607 33.394) rotate(135)" fill="none"
                          stroke="#fff" stroke-linecap="round" stroke-width="2"/>
                </g>
            </svg>
        </StyledCloseIconMobile>
    );
};

const StyledCloseIconMobile = styled.div`
  
`;

export default React.memo(CloseIconMobile);
