import React from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
const MyComponent = () => {
    const currentYear = new Date().getFullYear();

    return (
        <StyledComponent className={'main-footer'}>
            <Container>



                {/*copy right */}
                <Row className={'copyright'}>
                    <Col sm={3}>
                        <p>© {currentYear} Resume Saif Islam</p>
                    </Col>
                    <Col sm={4}>
                        <p>All Rights Reserved</p>
                    </Col>
                    <Col sm={{span: 4, offset:1}}>
                        <a target={'_blank'} href="https://devsopu.com/">DevInsights by Sopu – Learn, Share & Grow</a>
                    </Col>
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  position: relative;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background-color: rgba(7,7,7,.5);

  //footer copyright
  .copyright {
    margin-top: 60px;
    padding-top: 30px;
    padding-bottom: 30px;
    position: relative;
    &:after{
      content: '';
      top: 0;
      left: 0;
      right: 0;
      position: absolute;
      width: calc(100% - 30px);
      margin: 0 auto;
      height: 1px;
    }
    p, a {
      font-size: 12px;
      line-height: 24px;
      font-weight: 400;
    }
  }

  
  //responsive

  @media (max-width: 575px) {
    .copyright {
      margin-top: 60px;
    }
  }

`;

export default MyComponent;
